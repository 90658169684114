import { Model } from "@tailflow/laravel-orion/lib/model"
import { HttpMethod } from "@tailflow/laravel-orion/lib/drivers/default/enums/httpMethod"
import { AxiosResponse } from "axios"

export class User extends Model<{
  email?: string
  password?: string
  token?: string
  current_team_id?: number
}> {
  public async register(email: string, password: string, name: string): Promise<this> {
    await this.$query()
      .getHttpClient()
      .request<{ data: Array<Record<string, unknown>> }>("register", HttpMethod.POST, null, {
        email: email,
        password: password,
        password_confirmation: password,
        name: name,
      })

    return this
  }

  public async login(email: string, password: string, deviceName: string): Promise<this> {
    const response = await this.$query()
      .getHttpClient()
      .request<{ token: string }>("token", HttpMethod.POST, null, {
        email: email,
        password: password,
        device_name: deviceName,
      })

    this.$attributes.token = response.data.token

    return this
  }

  public async switchTeam(teamId: number): Promise<this> {
    const response = await this.$query()
      .getHttpClient()
      .request<{ token: string }>("me/current_team", HttpMethod.PUT, null, {
        team_id: teamId,
      })

    this.$attributes.current_team_id = teamId

    return this
  }

  public async me(): Promise<this> {
    const response = await this.$query()
      .getHttpClient()
      .request<{ token: string }>("me", HttpMethod.GET)

    this.$attributes = response.data

    return this
  }

  /**
   * Logout
   * @returns
   */
  public async logout(): Promise<this> {
    const response = await this.$query()
      .getHttpClient()
      .request<{ token: string }>("me/logout", HttpMethod.POST)

    this.$attributes = {}

    return this
  }

  /**
   * Logout all devices
   * @returns
   */
  public async logoutAllDevices(): Promise<this> {
    const response = await this.$query()
      .getHttpClient()
      .request<{ token: string }>("me/logout/all", HttpMethod.POST)

    this.$attributes = {}

    return this
  }

  public $resource(): string {
    return "users"
  }
}
