import { IconName } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"

const availableIcons = [...Object.values(fas), ...Object.values(far), ...Object.values(fab)]
const defaultIcon = "list-ol"

export const validateFaIcon = (iconName: string): IconName => {
  if (availableIcons.some((iconData) => iconData.iconName === iconName)) {
    return iconName as IconName
  } else {
    return defaultIcon as IconName
  }
}
