import React, { useEffect } from "react"
import { StyleSheet, View, Text, Button } from "react-native"
import RNModal from "react-native-modal"
import { Modal } from "./Modal"
import { ErrorMessage } from "app/models/ErrorMessage"
import { useState } from "react"
import { useFocusEffect } from "@react-navigation/native"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { TouchableOpacity } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { ScrollView } from "react-native"
import { SafeAreaView } from "react-native"
import { TextInput } from "react-native-gesture-handler"

type IconPickerProps = {
  isModalVisible: boolean
  title: string
  onSelect: (iconName: string) => void
  onCancel: () => void
}
export const IconPicker = ({
  isModalVisible = true,
  title = "Select icon",
  onSelect,
  onCancel,
}: IconPickerProps) => {
  const [modalVisible, setModalVisible] = useState(isModalVisible)
  const [visibleIcons, setVisibleIcons] = useState<string[]>([])
  const startIcons: string[] = [
    "faStar",
    "faHeart",
    "faThumbsUp",
    "faThumbsDown",
    "faEnvelope",
    "faSearch",
    "faUser",
    "faCog",
    "faCheck",
    "faTimes",
    "faInfoCircle",
    "faQuestionCircle",
    "faExclamationCircle",
    "faArrowUp",
    "faArrowDown",
    "faArrowLeft",
    "faArrowRight",
    "faBars",
    "faEllipsisV",
    "faEllipsisH",
    "faShareAlt",
    "faTrash",
    "faCalendar",
    "faMapMarker",
    "faEdit",
    "faDownload",
    "faUpload",
    "faLock",
    "faUnlock",
    "faFolder",
    "faFolderOpen",
    "faImage",
    "faVideo",
    "faMusic",
    "faPlay",
    "faPause",
    "faStop",
    "faFile",
    "faFileAlt",
    "faFilePdf",
    "faFileWord",
    "faFileExcel",
    "faFilePowerpoint",
    "faCopy",
    "faCut",
    "faPaste",
    "faUndo",
    "faRedo",
    "faSave",
    "faFont",
  ]

  const availableIcons = [...Object.keys(fas)]
  const filterIcons = (search: string) => {
    if (search.length == 0) {
      setVisibleIcons(startIcons)
    } else {
      const searchLowercase = search.toLowerCase()
      const filteredIcons = availableIcons.filter((icon) =>
        icon.toLowerCase().includes(searchLowercase),
      )
      setVisibleIcons(filteredIcons.slice(0, 48))
    }
  }

  const hide = () => {
    setModalVisible(false)
  }

  useEffect(() => {
    // set start icons to visible icons
    setVisibleIcons(startIcons)

    setModalVisible(isModalVisible)
  }, [isModalVisible])

  return (
    <View>
      <Modal isVisible={modalVisible}>
        <Modal.Container>
          <Modal.Header title={title} />
          <Modal.Body>
            <SafeAreaView style={{ flex: 1, maxHeight: "100%" }}>
              <ScrollView>
                <TextInput
                  style={{
                    height: 40,
                    borderColor: "gray",
                    borderWidth: 1,
                    marginBottom: 10,
                    paddingHorizontal: 10,
                    borderRadius: 5,
                    marginTop: 10,
                  }}
                  onChangeText={(text) => filterIcons(text)}
                  placeholder={"Search 3000+ icons"}
                ></TextInput>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {visibleIcons.map((icon) => (
                    <TouchableOpacity
                      key={icon}
                      style={{ padding: 4 }}
                      onPress={() => (hide(), onSelect(fas[icon].iconName))}
                    >
                      <FontAwesomeIcon icon={fas[icon]} size={40} />
                    </TouchableOpacity>
                  ))}
                  {visibleIcons.length == 0 && (
                    <View style={{ flex: 1, alignItems: "center", marginTop: 20 }}>
                      <Text>No icons found</Text>
                    </View>
                  )}
                </View>
              </ScrollView>
            </SafeAreaView>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button title={"ok"} onPress={() => (hide(), onSelect("asd"))} /> */}
            <Button title={"cancel"} onPress={() => (hide(), onCancel())} />
          </Modal.Footer>
        </Modal.Container>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 25,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    paddingTop: 10,
    textAlign: "center",
    fontSize: 24,
  },
  body: {
    justifyContent: "center",
    paddingHorizontal: 15,
    minHeight: 100,
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    flexDirection: "row",
  },
})
