import React, { FC, useMemo, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { MainTabNavigatorParamList } from "app/navigators"
import { useState } from "react"
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from "react-native"
import { colors } from "app/theme"
import { Orion } from "@tailflow/laravel-orion/lib/orion"

import { SafeAreaView } from "react-native";
import {DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors} from '@dnd-kit/core';

import {TemplateEditorBlock} from 'app/components/TemplateEditorBlock';
import {TemplateEditorColumn} from 'app/components/TemplateEditorColumn';
import {TemplateEditorContent} from 'app/components/TemplateEditorContent';
import {TemplateEditorContentRowDraggable} from 'app/components/TemplateEditorContentRowDraggable';
import {TemplateEditorContentRow} from 'app/components/TemplateEditorContentRow';
import { TemplateEditorContentColumn } from 'app/components/TemplateEditorContentColumn';
import { SortableContainer } from "app/components/TemplateEditorContainer";
import { Container } from "app/components/TemplateEditorContainer";
import { SortableItem } from "app/components/TemplateEditorSortableItem";
import { Item } from "app/components/TemplateEditorSortableItem";
import { Button } from "app/components"
import { Allotment } from "allotment";
import { SortableOverlay } from "app/components/TemplateEditorContentRowSortableOverlay"

import "allotment/dist/style.css";
import {
arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

const renderRow = (item) => (
  <TemplateEditorContentRow key={item.id} id={item.id} child={item.children}>
    {item.id}
  </TemplateEditorContentRow>
);

const renderColumn= (item) => (
  <TemplateEditorContentColumn key={item.id} id={item.id} child={item.children}>
    {item.id}
  </TemplateEditorContentColumn>
);

const renderItem = (item) => {
  if(item.type == "row") {
    return renderRow(item);
  } else if(item.type == "col"){
    return renderColumn(item);
  }
}
  
 
;


export const TemplateScreen: FC<MainTabNavigatorParamList<"template">> = observer(function TemplateScreen( {
  navigation,
}) {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const navigation = useNavigation()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  const containers = [
  ['A', 'B', 'C'],
  ['A', 'B', 'C'], 
  ['A', 'B'],
  ['A', 'B', 'C'],
  ];

  const wrapperStyle = {
    background: "#e9e9e9",
    padding: "50px 10px",
    borderRadius: 8,
    margin: 50
  };

  const droppableRows = ['1', '2', '3', '4'];

 
  
  const [items, setItems] = useState([]);


  const [activeId, setActiveId] = useState(null);
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );

  const [parent, setParent] = useState(null);
  const draggableMarkup = (
    <TemplateEditorBlock id="draggable">Drag me</TemplateEditorBlock>
  );
  useEffect(() => {
    // Your function call here
    console.log('Component is ready');
    loadContent();
    
    // Optional: Any cleanup action can go here, which will run when the component unmounts
    return () => {
      console.log('Component will unmount');
    };
  }, []); // Passing an empty dependency array to run only once on mount


  function getDragOverlay() {
    if (!activeId) {
      return null;
    }
    console.log("getDragOverlay")
    console.log(activeId)
    console.log(isContainer(activeId))

    if (isContainer(activeId)) {
      const item = items.find((i) => i.id === activeId);

      return (
        <Container row={item.row}>
          {getItems(activeId).map((item) => (
            <Item key={item.id} id={item.id} />
          ))}
        </Container>
      );
    }

    return <Item id={activeId} />;
  }

  function handleDragEnd(event) {
    const {over} = event;
    //console.log("over " + over)
    //console.log( event)

    let target = findDroppable()

    if (over && active.id !== over?.id) {
      const activeIndex = items.findIndex(({ id }) => id === active.id);

      const overIndex = items.findIndex(({ id }) => id === over.id);
      setItems(arrayMove(items, activeIndex, overIndex));
    }
    setActive(null);

  }

  function isContainer(id) {
    const item = items.find((item) => item.id === id);

    return !item ? false : item.container;
  }

  function isRow(id) {
    const item = items.find((item) => item.id === id);

    return !item ? false : item.row;
  }

  function getItems(parent) {
    console.log(items)
    return items.filter((item) => {
      if (!parent) {
        return !item.parent;
      }

      return item.parent === parent;
    });
  }

  function getItemIds(parent) {
    return getItems(parent).map((item) => item.id);
  }

  function findParent(id) {
    const item = items.find((item) => item.id === id);
    return !item ? false : item.parent;
  }

  function handleDragOver(event) {
    
    console.log("handleDragOver")
    //const { active, over, draggingRect } = event;
    const active = event.active;
    const over = event.over;
    const draggingRect = event.active.rect;
    console.log("handleDragOver")
    //console.log(event)
    console.log(active)
    console.log(over)
    console.log(draggingRect)
    console.log("handleDragOver2")

    const { id } = active;
    let overId;
    if (over) {
      overId = over.id;
    }

    const overParent = findParent(overId);
    const overIsContainer = isContainer(overId);
    const activeIsContainer = isContainer(activeId);
    if (overIsContainer) {
      const overIsRow = isRow(overId);
      const activeIsRow = isRow(activeId);
      // only columns to be added to rows
      if (overIsRow) {
        if (activeIsRow) {
          return;
        }

        if (!activeIsContainer) {
          return;
        }
      } else if (activeIsContainer) {
        return;
      }
    } 
      const activeIndex = items.findIndex((item) => item.id === id);
      const overIndex = items.findIndex((item) => item.id === overId);

      let newIndex = overIndex;
      console.log("over.rect")
      console.log(over)

      let isBelowLastItem;
      if(over) {
        isBelowLastItem =
        over &&
        overIndex === items.length - 1 &&
        draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;
      } else {
        isBelowLastItem = false;
      }
      

      const modifier = isBelowLastItem ? 1 : 0;

      newIndex = overIndex >= 0 ? overIndex + modifier : items.length + 1;

      let nextParent;
      if (overId) {
        nextParent = overIsContainer ? overId : overParent;
      }

      items[activeIndex].parent = nextParent;
      const nextItems = arrayMove(items, activeIndex, newIndex);


      setItems(nextItems);
     
   
  }

  function findDroppable(){
    return ;
  }

  function handleDragStart(event) {
    setActiveId(event.active.id);
    console.log("DRAGGING " + activeId)
    console.log(event)
    setActive(event.active);

  }

  function addRow() {

  }

  function addColumn() {

  }

  function addItem(container, row) {
    console.log("add item")
      let itemsNew = [...items]; 
      
      itemsNew.push(
        {
          id: items.length + 1,
          container: container,
          row: row
        }
      )
      console.log(items)
      setItems(itemsNew);
      console.log(items)
  }

  function loadContent(){
    console.log("!!!!!!!!!!!!LOADING CONTENT!!!!!!!!!!!!") 
    let itms = []
    addItem(true,true)
    addItem(true,true)
    addItem(true, false)
    addItem(true,true)
    addItem(true, false)
    addItem(true,true)
    addItem(true, false)
    addItem(false,false)
    /*itms.push(
      {
        id: 1,
        container: true,
        key: 222,
        type: "row",
        children: [
          {
            id: 2,
            type: "col",
            children: [
              {
                id: 2,
                type: "text",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },
          {
            id: 2,
            type: "col",
            container: true,
            children: [
              {
                id: 2,
                type: "image",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },{
            id: 2,
            type: "col",
            children: [
              {
                id: 2,
                type: "image",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },
          
        ],
        content: null
      }
    )
    itms.push(
      {
        id: 7,
        key: 222,
        type: "row",
        children: [
          {
            id: 2,
            type: "col",
            children: [
              {
                id: 2,
                type: "text",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },
          {
            id: 2,
            type: "col",
            children: [
              {
                id: 2,
                type: "image",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },{
            id: 2,
            type: "col",
            children: [
              {
                id: 2,
                type: "image",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },
          
        ],
        content: null
      }
    )
    itms.push(
      {
        id: 9,
        key: 222,
        type: "row",
        children: [
          {
            id: 2,
            type: "col",
            children: [
              {
                id: 2,
                type: "text",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },
          {
            id: 2,
            type: "col",
            children: [
              {
                id: 2,
                type: "image",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },{
            id: 2,
            type: "col",
            children: [
              {
                id: 2,
                type: "image",
                children: [
                  
                ],
                content: null
              },
            ],
            content: null
          },
          
        ],
        content: null
      }
    )

    setItems(itms);*/
  }

  



  /** {droppableRows.map((id) => (
                <TemplateEditorContentRow id={id} key={id}>
                  Droppable container id: ${id}
                  {parent === id ? draggableMarkup : 'Drop here'}
                </TemplateEditorContentRow>
              ))}*/



  /**<Allotment vertical={true}>
                {containers.map((item, index) => (
                  <div style={styles.editorRows}>
                    <Allotment vertical={false}>
                      {item.map((column, id) => (
                      // We updated the Droppable component so it would accept an `id`
                      // prop and pass it to `useDroppable`
                      <TemplateEditorColumn key={id} id={index+""+id}>
                        {parent === index+""+id ? draggableMarkup : 'Drop here'}
                      </TemplateEditorColumn>
                      ))}
                    </Allotment>
                    <div>
                    { 
                          item.length < 3  ? <Button onPress={() => addColumn(index)}>+</Button> : ""
                      }
                     </div>

                  </div>
                ))}
              </Allotment> */

              

              
  return (
    <div>
      <div>
        <Text onPress={()=> addItem(false,false)}>Add item</Text>
        <Text onPress={()=> addItem(true,false)}>Add column</Text>

        <Text onPress={()=> addItem(true,true)}>Add Row</Text>
        
      </div>
    <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
      
        <SortableContext
          id="root"
          items={items}
          strategy={verticalListSortingStrategy}
        >
          <div style={wrapperStyle}>
            {items.map((item) => {
              
              if (item.container) {
                return (
                  <SortableContainer
                    key={item.id}
                    id={item.id}
                    getItems={getItems}
                    row={item.row}
                  />
                );
              }

              return (
                <SortableItem key={item.id} id={item.id}>
                  <Item id={item.id} />
                </SortableItem>
              );
            })}
          </div>
        </SortableContext>
        <DragOverlay>{getDragOverlay()}</DragOverlay>
      </DndContext>
      
      </div>

  )
})

const $root: ViewStyle = {
  flex: 1,
}
const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    backgroundColor: colors.background,
  },
  flexBody: {
    flex: 1,
    backgroundColor: colors.background,
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  SectionStyle: {
    flexDirection: "row",
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: "#7DE24E",
    borderWidth: 0,
    color: "#FFFFFF",
    borderColor: "#7DE24E",
    height: 40,
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: "#FFFFFF",
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: "black",
    backgroundColor: "#fff",
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: "#dadae8",
  },
  registerTextStyle: {
    color: "#000",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 16,
    alignSelf: "center",
    padding: 10,
    textDecorationLine: "underline",
  },
  errorTextStyle: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },
  editorContainer:{
    textAlign: "center",
    width: "100%",
  },
  editorRows:{
    width: "100%",
    flex: 1,
    minHeight:80,
    height: 50
  },
  rightSidebarContainer:{
    flex: 1
  },
  mainContainer:{
    flex: 5
  },
  innerEditorContainer:{
    height:1000
  }

})
