import React, { useEffect } from "react"
import { StyleSheet, View, Text, Button } from "react-native"
import RNModal from "react-native-modal"
import { Modal } from "./Modal"
import { ErrorMessage } from "app/models/ErrorMessage"
import { useState } from "react"
import { useFocusEffect } from "@react-navigation/native"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { TouchableOpacity } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { ScrollView } from "react-native"
import { SafeAreaView } from "react-native"
import { DataGroup } from "app/models"
import { validateFaIcon } from "app/utils/uiUtils"
import { Col, Row, Grid } from "react-native-easy-grid"
import moment from "moment"
import { Divider } from "@rneui/base"
import { colors } from "app/theme"
import { DataGroupPreview } from "./DataGroupPreview"
import { Ionicons } from "@expo/vector-icons"
import { DataImage } from "./DataImage"
import { DataFilePreview } from "./DataFilePreview"

type DataGroupOverviewProps = {
  dataGroup: DataGroup
  children?: React.ReactNode
  // callback
  onSelect?: (dataGroup: DataGroup) => void
}
export const DataGroupOverview = ({ dataGroup, children, onSelect }: DataGroupOverviewProps) => {
  return (
    <View>
      <TouchableOpacity
        style={styles.dataTemplateButton}
        key={dataGroup.$attributes.id}
        activeOpacity={0.5}
        onPress={() => {
          // if callback is set call it
          if (onSelect) {
            onSelect(dataGroup)
          }
        }}
      >
        <Grid style={{ flex: 1 }}>
          <Row style={{ marginHorizontal: 15, marginTop: 8, marginBottom: 5 }}>
            <Col>
              <View style={{ alignItems: "flex-start", flexDirection: "row" }}>
                <FontAwesomeIcon icon={validateFaIcon(dataGroup.$attributes.icon)} size={25} />
                <Text style={{ marginLeft: 10, fontSize: 16, fontWeight: "bold" }}>
                  {dataGroup.$attributes.name}
                </Text>
              </View>
            </Col>
            <Col>
              <Text>{moment(dataGroup.$attributes.created_at).fromNow()}</Text>
            </Col>
          </Row>
        </Grid>
        {dataGroup.$relations.datas.length > 0 && (
          <View style={{ paddingHorizontal: 10, paddingBottom: 10 }}>
            <Divider style={{ margin: 0 }} />
            <View style={{}}>
              {dataGroup.$relations.datas.map((data) => (
                <View
                  key={data.$attributes.id}
                  style={{ flexDirection: "row", marginTop: 5, alignItems: "center" }}
                >
                  {data.$attributes.name && (
                    <View style={{ marginRight: 5 }}>
                      <Text>{data.$attributes.name}:</Text>
                    </View>
                  )}

                  <View style={{ flex: 1 }}>
                    {data.$attributes.data_type == "text" && (
                      <Text style={{ textAlign: "left" }}>{data.$attributes.data}</Text>
                    )}
                    {data.$attributes.data_type == "number" && (
                      <Text style={{ textAlign: "left" }}>{data.$attributes.data}</Text>
                    )}
                    {data.$attributes.data_type == "date" && (
                      <Text style={{ textAlign: "left" }}>{data.$attributes.data}</Text>
                    )}
                    {data.$attributes.data_type == "image" && (
                      <View>
                        <DataImage data={data}></DataImage>
                      </View>
                    )}
                    {data.$attributes.data_type == "file" && (
                      <View>
                        <DataFilePreview data={data}></DataFilePreview>
                      </View>
                    )}
                    {data.$attributes.data_type == "boolean" && (
                      <View>
                        {data.$attributes.data == "true" ? (
                          <Text style={{ textAlign: "left" }}>
                            <Ionicons name="checkmark-circle" size={18} color="green" />
                          </Text>
                        ) : (
                          <Text style={{ textAlign: "left" }}>
                            <Ionicons name="close-circle" size={18} color="grey" />
                          </Text>
                        )}
                      </View>
                    )}
                    {data.$attributes.data_selected == true &&
                      data.$attributes.data_type == "select" && (
                        <View style={{}}>
                          <DataGroupPreview
                            dataGroup={data.$relations.data_selected_data_group as DataGroup}
                          ></DataGroupPreview>
                        </View>
                      )}
                  </View>
                </View>
              ))}
            </View>
          </View>
        )}
      </TouchableOpacity>
      {children}
      {/* 
      <TouchableOpacity style={styles.dataTemplateButton}>
        <View style={{ borderWidth: 5, flexDirection: "row", borderColor: "green" }}>
          <View>
            <Text>afsddasfadfsasdf</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text>1111 1 1 1 1 1 1 </Text>
            <Text>22 2 2 2 22 2 2 2 2</Text>
          </View>
        </View>
        <View style={{ borderWidth: 5, flexDirection: "row", borderColor: "green" }}>
          <View>
            <Text>afsddasfadfsasdf</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text>1111 1 1 1 1 1 1 </Text>
            <Text>
              22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2
              2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2 22 2 2 2
              22 2 2 2 22 2 2 2 xx xx xx
            </Text>
          </View>
        </View>
      </TouchableOpacity> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 25,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  dataTemplateButton: {
    borderColor: colors.cardBorder,
    borderWidth: 1,
    margin: 10,
    marginBottom: 0,
    textAlign: "right",
    borderRadius: 10,
    backgroundColor: colors.card,
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    paddingTop: 10,
    textAlign: "center",
    fontSize: 24,
  },
  body: {
    justifyContent: "center",
    paddingHorizontal: 15,
    minHeight: 100,
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    flexDirection: "row",
  },
})
