export class ErrorMessage {
  title: string
  message: string
  details: string

  constructor(title: string, message?: string, details?: string) {
    this.title = title
    this.message = message
    this.details = details
  }

  public static fromException(error: any, objectName?: string, action?: string): ErrorMessage {
    console.log("ERROR")
    if (error.response) {
      console.log(error.response)
      // If 404 return nor found message
      if (error.response.status === 404) {
        if (objectName) {
          return new ErrorMessage(
            `${objectName} not found`,
            `The ${objectName} could not be found. Item might have been deleted or moved.`,
          )
        }
        return new ErrorMessage(
          "Item not found",
          `Requested Item could not be found. Item might have been deleted or moved.`,
        )
      }
      if (error.response.status === 503) {
        return new ErrorMessage(
          "Service maintenance",
          `Our service is currently under maintenance. Please try again later.`,
        )
      }
      if (error.response.status === 422) {
        if (action) {
          return new ErrorMessage(`${action} failed`, error.response.data.message)
        }
        return new ErrorMessage("Error occured", `The request is invalid. Please check your input.`)
      }
    }

    console.log(error)

    // check if function exists
    if (error.toJSON && typeof error.toJSON === "function") {
      if (error.toJSON().message === "Network Error") {
        return new ErrorMessage("Network Error", `Please check your internet connection.`)
      }
    }

    return new ErrorMessage("Error", error.message)
  }
}
