import React, { createContext, useContext, useMemo } from "react";
import {SortableContext, horizontalListSortingStrategy, useSortable} from '@dnd-kit/sortable';
import {useDroppable, DraggableSyntheticListeners,
  UniqueIdentifier} from '@dnd-kit/core';

interface Props {
  id: UniqueIdentifier;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {}
});

export function TemplateEditorContentColumn(props) {
  const {attributes,
    listeners,
    setNodeRef,
    isDragging,
    setActivatorNodeRef,
    transform,
    transition} = useSortable({
    id: props.id,
  });
  const style = {
    backgroundColor: "blue",
    flex:1,
    height:"100%",
  };
  
  function contentElementMarkup(type: string, id: string) {

    if(type === "image") {
      return (
        <h1 key={id} id="draggable">img</h1>
      )
    } else {
      return (
        <h1  key={id} id="draggable">text</h1>
      )
    }
    
  }
    

  return (
    <div ref={setNodeRef} style={style} id={"1"} key={props.id}>
      <button className="DragHandle"   {...attributes}  {...listeners}>
      <svg viewBox="0 0 20 20" width="12">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </button> 
      {props.child.map((column, id) => (
        // We updated the Droppable component so it would accept an `id`
        // prop and pass it to `useDroppable`
        contentElementMarkup(column.type,id)
        
      ))}
    </div>
  );
}