import { Model } from "@tailflow/laravel-orion/lib/model"
import { HttpMethod } from "@tailflow/laravel-orion/lib/drivers/default/enums/httpMethod"
import { AxiosResponse } from "axios"
import { Data } from "./Data"
import Config from "app/config"

export class File extends Model<{
  data?: string
}> {
  /**
   * Get file as base64
   * @returns
   */
  public async getFileBase64(data: Data): Promise<this> {
    const response = await this.$query()
      .getHttpClient()
      .request<{ file: string }>("data/" + data.$attributes.id + "/file/base64", HttpMethod.GET)

    this.$attributes = {
      data: response.data.file,
    }

    return this
  }

  public $resource(): string {
    return "files"
  }
}
