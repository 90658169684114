import AsyncStorage from "@react-native-async-storage/async-storage"
import { Orion } from "@tailflow/laravel-orion/lib/orion"

export const logout = () => {
  AsyncStorage.removeItem("api_token")
  Orion.setToken("")
}
export const login = (token: string) => {
  AsyncStorage.setItem("api_token", token)
  Orion.setToken(token)
}
