import React, { useEffect } from "react"
import { StyleSheet, View, Button } from "react-native"
import { Modal } from "./Modal"
import { useState } from "react"
import { TouchableOpacity } from "react-native-gesture-handler"
import { ScrollView } from "react-native"
import { SafeAreaView } from "react-native"
import { DataTemplateItem } from "app/models"
import { FlatList } from "react-native"
import { ListRenderItem } from "react-native"
import { colors } from "app/theme"
import { Text } from "./Text"
import { TxKeyPath } from "app/i18n"
import { Divider } from "@rneui/base"
import { DataTemplate } from "app/models"
import { SortDirection } from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection"
import { ErrorMessage } from "app/models/ErrorMessage"
import { ActivityIndicator } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { validateFaIcon } from "app/utils/uiUtils"

type DataTypePickerProps = {
  isModalVisible: boolean
  title: string
  onSelect: (
    data_type: string,
    data_force_select: boolean,
    data_select_from_data_template?: DataTemplate,
  ) => void
  onCancel: () => void
}
export const DataTypePicker = ({
  isModalVisible = true,
  title = "Select field type",
  onSelect,
  onCancel,
}: DataTypePickerProps) => {
  const [modalVisible, setModalVisible] = useState(isModalVisible)
  const availableStandaloneDataTypes = DataTemplateItem.availableStandaloneDataTypes()
  const availableLinkedDataTypes = DataTemplateItem.availableLinkedDataTypes()
  const [selectedLinkedDataType, setSelectedLinkedDataType] = useState<string>()
  const [dataTemplates, setDataTemplates] = useState<DataTemplate[]>([])
  const [loading, setLoading] = useState(false)

  const hide = () => {
    setModalVisible(false)
  }

  useEffect(() => {
    setModalVisible(isModalVisible)
  }, [isModalVisible])

  /**
   * User selected a data type -> return to parent
   * @param data_type
   * @param data_force_select
   * @param data_select_from_data_template
   */
  const typeSelected = (
    data_type: string,
    data_force_select?: boolean,
    data_select_from_data_template?: DataTemplate,
  ) => {
    setSelectedLinkedDataType(undefined)
    setDataTemplates([])
    hide()
    onSelect(data_type, data_force_select, data_select_from_data_template)
  }

  const fetchDataTemplates = () => {
    setLoading(true)
    DataTemplate.$query()
      .sortBy("last_used_at", SortDirection.Desc)
      .search()
      .then((response) => {
        setDataTemplates(response)
        setLoading(false)
      })
      .catch((error) => {
        alert(ErrorMessage.fromException(error, "DataTemplate"))
      })
  }

  const renderStandaloneDataTypeSelection: ListRenderItem<string> = (dataTemplate) => (
    <View style={[styles.typeSelectButton]}>
      <TouchableOpacity
        activeOpacity={0.5}
        onPress={() => {
          typeSelected(dataTemplate.item)
        }}
      >
        <View style={styles.btnContainer}>
          <Text
            style={{ fontSize: 20, textAlign: "center" }}
            tx={("dataTypePicker.dataTypes." + dataTemplate.item) as TxKeyPath}
          ></Text>
          <Text
            style={{ fontSize: 12, textAlign: "center" }}
            tx={("dataTypePicker.dataTypes." + dataTemplate.item + "_description") as TxKeyPath}
          ></Text>
        </View>
      </TouchableOpacity>
    </View>
  )

  const renderLinkedDataTypeSelection: ListRenderItem<string> = (dataTemplate) => (
    <View>
      {selectedLinkedDataType == undefined || selectedLinkedDataType == dataTemplate.item ? (
        <View style={[styles.typeSelectButton]}>
          <TouchableOpacity
            activeOpacity={0.5}
            onPress={() => {
              if (selectedLinkedDataType != dataTemplate.item) {
                setSelectedLinkedDataType(dataTemplate.item)
                fetchDataTemplates()
              } else {
                setSelectedLinkedDataType(undefined)
              }
            }}
          >
            <View style={styles.btnContainer}>
              <Text
                style={{ fontSize: 20, textAlign: "center" }}
                tx={("dataTypePicker.dataTypes." + dataTemplate.item) as TxKeyPath}
              ></Text>
              <Text
                style={{ fontSize: 12, textAlign: "center" }}
                tx={("dataTypePicker.dataTypes." + dataTemplate.item + "_description") as TxKeyPath}
              ></Text>
            </View>
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}
    </View>
  )

  return (
    <View>
      <Modal isVisible={modalVisible}>
        <Modal.Container>
          <Modal.Header title={title} />
          <Modal.Body>
            <SafeAreaView style={{ flex: 1, maxHeight: "100%" }}>
              <ScrollView>
                <View>
                  {selectedLinkedDataType == undefined ? (
                    <View>
                      <View style={{ justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ textAlign: "center", fontSize: 20 }}>Basic types</Text>
                      </View>
                      <FlatList
                        data={availableStandaloneDataTypes}
                        style={{ flex: 1, marginBottom: 15 }}
                        renderItem={renderStandaloneDataTypeSelection}
                        keyExtractor={(item) => item}
                        numColumns={2}
                      />
                      <Divider />
                    </View>
                  ) : (
                    <></>
                  )}
                  <View style={{ justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                    <Text style={{ textAlign: "center", fontSize: 20 }}>Link to other data</Text>
                  </View>
                  <FlatList
                    data={availableLinkedDataTypes}
                    style={{ flex: 1 }}
                    renderItem={renderLinkedDataTypeSelection}
                    keyExtractor={(item) => item}
                    numColumns={1}
                  />
                </View>

                {loading ? (
                  <ActivityIndicator size="large" color={colors.brandColor} />
                ) : (
                  <View style={{ marginTop: 10 }}>
                    <View>
                      <ScrollView horizontal={true}>
                        {dataTemplates &&
                          dataTemplates.map((dataTemplate) => (
                            <View key={dataTemplate.$attributes.id}>
                              <TouchableOpacity
                                style={{
                                  justifyContent: "center",
                                  backgroundColor: colors.card,
                                  borderRadius: 10,
                                  margin: 10,
                                  padding: 10,
                                }}
                                key={dataTemplate.$attributes.id}
                                activeOpacity={1}
                                onPress={() => {
                                  typeSelected(selectedLinkedDataType, true, dataTemplate)
                                }}
                              >
                                <Text style={{ textAlign: "center" }}>
                                  <FontAwesomeIcon
                                    icon={validateFaIcon(dataTemplate.$attributes.icon)}
                                    size={20}
                                  />
                                </Text>
                                <Text style={{ textAlign: "center" }}>
                                  {dataTemplate.$attributes.name}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          ))}
                      </ScrollView>
                    </View>
                  </View>
                )}
              </ScrollView>
            </SafeAreaView>
          </Modal.Body>
          <Modal.Footer>
            <Button
              title={"cancel"}
              onPress={() => (setSelectedLinkedDataType(undefined), hide(), onCancel())}
            />
          </Modal.Footer>
        </Modal.Container>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 25,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    paddingTop: 10,
    textAlign: "center",
    fontSize: 24,
  },
  typeSelectButton: {
    flex: 1,
    minHeight: 100,
    borderColor: colors.cardBorder,
    borderWidth: 1,
    margin: 10,
    textAlign: "right",
    borderRadius: 10,
    backgroundColor: colors.card,
  },
  body: {
    justifyContent: "center",
    paddingHorizontal: 15,
    minHeight: 100,
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    flexDirection: "row",
  },

  btnContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
})
