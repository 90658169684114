const en = {
  common: {
    ok: "OK!",
    cancel: "Cancel",
    back: "Back",
  },
  welcomeScreen: {
    postscript:
      "psst  — This probably isn't what your app looks like. (Unless your designer handed you these screens, and in that case, ship it!)",
    readyForLaunch: "Your app, almost ready for launch!",
    exciting: "(ohh, this is exciting!)",
  },
  errorScreen: {
    title: "Something went wrong!",
    friendlySubtitle:
      "This is the screen that your users will see in production when an error is thrown. You'll want to customize this message (located in `app/i18n/en.ts`) and probably the layout as well (`app/screens/ErrorScreen`). If you want to remove this entirely, check `app/app.tsx` for the <ErrorBoundary> component.",
    reset: "RESET APP",
  },
  emptyStateComponent: {
    generic: {
      heading: "So empty... so sad",
      content: "No data found yet. Try clicking the button to refresh or reload the app.",
      button: "Let's try this again",
    },
  },
  dataTypePicker: {
    dataTypes: {
      text: "Text",
      text_description: "Enter a text",
      number: "Number",
      number_description: "Enter a number",
      date: "Date",
      date_description: "Select date and/or time",
      boolean: "Toggle",
      boolean_description: "Toggle between ✅ and ❌",
      image: "Image",
      image_description: "Upload an image",
      file: "File",
      file_description: "Upload an file",
      select: "Select one",
      select_description: "Link this item to existing data. E.g. customers, products, etc.",
    },
  },
}

export default en
export type Translations = typeof en
