import { Model } from "@tailflow/laravel-orion/lib/model"
import { HttpMethod } from "@tailflow/laravel-orion/lib/drivers/default/enums/httpMethod"
import { AxiosResponse } from "axios"

export class Team extends Model<{
  name?: string
  id?: number
  personal_team?: boolean
  created_at?: Date
  updated_at?: Date
}> {
  public $resource(): string {
    return "teams"
  }
}
