import React from "react"
import { StyleSheet, View, Text, Button } from "react-native"
import RNModal from "react-native-modal"
import { ScrollView } from "react-native"
type ModalProps = {
  isVisible: boolean
  children: React.ReactNode
  [x: string]: any
}
export const Modal = ({ isVisible = false, children, ...props }: ModalProps) => {
  return (
    <RNModal
      animationIn="fadeIn"
      animationOut="fadeOut"
      isVisible={isVisible}
      animationInTiming={500}
      animationOutTiming={100}
      backdropTransitionInTiming={500}
      backdropTransitionOutTiming={100}
      {...props}
    >
      <ScrollView>{children}</ScrollView>
    </RNModal>
  )
}

const ModalContainer = ({ children }: { children: React.ReactNode }) => (
  <View style={styles.container}>{children}</View>
)

const ModalHeader = ({ title }: { title: string }) => (
  <View style={styles.header}>
    <Text style={styles.text}>{title}</Text>
  </View>
)

const ModalBody = ({ children }: { children?: React.ReactNode }) => (
  <View style={styles.body}>{children}</View>
)

const ModalFooter = ({ children }: { children?: React.ReactNode }) => (
  <View style={styles.footer}>{children}</View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 25,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    paddingTop: 10,
    textAlign: "center",
    fontSize: 24,
  },
  body: {
    justifyContent: "center",
    paddingHorizontal: 15,
    minHeight: 100,
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    flexDirection: "row",
  },
})

Modal.Header = ModalHeader
Modal.Container = ModalContainer
Modal.Body = ModalBody
Modal.Footer = ModalFooter
