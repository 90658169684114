/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import { DarkTheme, DefaultTheme, NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator, NativeStackScreenProps } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import React from "react"
import { useColorScheme } from "react-native"
import * as Screens from "app/screens"
import Config from "../config"
import { navigationRef, useBackButtonHandler } from "./navigationUtilities"
import { colors } from "app/theme"
import { Ionicons } from "@expo/vector-icons"
import { CompositeScreenProps } from "@react-navigation/native"
import { StyleSheet, TextStyle, ViewStyle } from "react-native"
import { createBottomTabNavigator, BottomTabScreenProps } from "@react-navigation/bottom-tabs"
import { Image } from "react-native"
import { TouchableOpacity } from "react-native"
import { useNavigation, useRoute } from "@react-navigation/native"
import { View } from "react-native"
import { DataTemplate } from "app/models/DataTemplate"
import { DataTemplateItem } from "app/models/DataTemplateItem"
import { DataGroup } from "app/models/DataGroup"
import { Screen, Text } from "app/components"
import { useWindowDimensions } from "react-native"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 *   https://reactnavigation.org/docs/typescript/#organizing-types
 */

/**
 * This file is a mess and needs refactoring
 */

/**
 * This is a list of all the route names that will exit the app if the back button
 * is pressed while in that screen. Only affects Android.
 */
const exitRoutes = Config.exitRoutes

const headerAccountBtn = () => {
  return (
    <TouchableOpacity
      onPress={() =>
        // navigate to account page
        navigationRef.current.navigate("account")
      }
    >
      <View style={{ padding: 10 }}>
        <Ionicons name="person-circle-outline" size={40} color="#555" />
      </View>
    </TouchableOpacity>
  )
}

const headerTitleLogoDynamic = () => {
  if (useWindowDimensions().width > 400) {
    return <Image source={require("assets/logo/templagon.svg")} style={styles.logo} />
  }
  return <Image source={require("assets/logo/templagonIcon.svg")} style={styles.logoIco} />
}
const headerTitleLogoStatic = () => {
  return <Image source={require("assets/logo/templagon.svg")} style={styles.logo} />
}

/**
 * Data Dash Stack Navigator
 */

export type DataDashboardStackParamList = {
  datadashboard: undefined
  datasettings: undefined
  edit_data_template: { dataTemplate: DataTemplate }
  editdatagroup: { dataGroup: DataGroup }
  create_data_template: undefined
}

export type DataDashboardStackScreenProps<T extends keyof DataDashboardStackParamList> =
  NativeStackScreenProps<DataDashboardStackParamList, T>

const DataDashboardStackNavi = createNativeStackNavigator<DataDashboardStackParamList>()
export const DataDashboardStack = observer(function DataDashboardStack() {
  return (
    <DataDashboardStackNavi.Navigator
      screenOptions={{
        headerShown: true,
        headerTitleAlign: "left",
        headerTitle: headerTitleLogoStatic,
      }}
    >
      <DataDashboardStackNavi.Screen
        name="datadashboard"
        component={Screens.DataDashboardScreen}
        options={({ route, navigation }) => ({
          // headerLeft: () => null,
          headerRight: () => (
            <TouchableOpacity
              onPress={() =>
                // navigate to account page
                navigation.navigate("datasettings")
              }
            >
              <View style={{ padding: 10 }}>
                <Ionicons name="settings-outline" size={25} color="#555" />
              </View>
            </TouchableOpacity>
          ),
        })}
      />
      <DataDashboardStackNavi.Screen
        name="editdatagroup"
        component={Screens.DataGroupScreen}
        options={({ route, navigation }) => ({
          headerTitle:
            "Edit " + (route.params as { dataGroup: DataGroup }).dataGroup.$attributes.name,
          // headerLeft: () => null,
          headerRight: () => <></>,
        })}
      />
      <DataDashboardStackNavi.Screen
        name="edit_data_template"
        component={Screens.DataTemplateScreen}
        options={({ route, navigation }) => ({
          headerTitle:
            "Edit " +
            (route.params as { dataTemplate: DataTemplate }).dataTemplate.$attributes.name,
          // headerLeft: () => null,
          headerRight: () => <></>,
        })}
      />
      <DataDashboardStackNavi.Screen
        name="datasettings"
        component={Screens.DataSettingsScreen}
        options={({ route, navigation }) => ({
          headerTitle: "Note settings",
          // headerLeft: () => null,
          headerRight: () => <></>,
        })}
      />
      <DataDashboardStackNavi.Screen
        name="create_data_template"
        component={Screens.DataTemplateScreen}
        options={({ route, navigation }) => ({
          headerTitle: "Create new note type",
          // headerLeft: () => null,
          headerRight: () => <></>,
        })}
      />
    </DataDashboardStackNavi.Navigator>
  )
})

/**
 * Data Add Stack Navigator
 */

export type DataAddStackParamList = {
  datagroup: {
    dataTemplate?: DataTemplate
    dataTemplateItems?: DataTemplateItem[]
    dataGroup?: DataGroup
  }
  datatemplates: { notification?: string }
  datatemplate: undefined
}

export type DataAddStackScreenProps<T extends keyof DataAddStackParamList> = NativeStackScreenProps<
  DataAddStackParamList,
  T
>

const DataAddStackNavi = createNativeStackNavigator<DataAddStackParamList>()
export const DataAddStack = observer(function DataAddStack() {
  return (
    <DataAddStackNavi.Navigator
      screenOptions={{
        headerShown: true,
        headerTitleAlign: "left",
        headerTitle: headerTitleLogoStatic,
        headerRight: headerAccountBtn,
      }}
    >
      <DataAddStackNavi.Screen
        name="datatemplates"
        component={Screens.DataTemplatesScreen}
        initialParams={{ notification: "" }}
        options={({ route, navigation }) => ({
          headerLeft: () => null,
          headerRight: () => (
            <TouchableOpacity
              onPress={() => {
                // navigate to account page
                const rootnav = navigationRef.current
                navigation.navigate("main", {
                  screen: "notes",
                  params: {
                    screen: "datasettings",
                  },
                })
              }}
            >
              <View style={{ padding: 10 }}>
                <Ionicons name="settings-outline" size={25} color="#555" />
              </View>
            </TouchableOpacity>
          ),
        })}
      />
      <DataAddStackNavi.Screen
        name="datatemplate"
        component={Screens.DataTemplateScreen}
        options={({ route, navigation }) => ({
          headerTitle: "Add new data template",
          headerRight: () => null,
        })}
      />
      <DataAddStackNavi.Screen
        name="datagroup"
        component={Screens.DataGroupScreen}
        options={({ route }) => ({
          headerTitle: "Add new data: " + route.params.dataTemplate.$attributes.name,
          // headerLeft: () => null,
        })}
      />
    </DataAddStackNavi.Navigator>
  )
})

/**
 * Home Stack Navigator
 */

export type HomeStackParamList = {
  dashboard: undefined
  test: undefined
}
export type HomeStackScreenProps<T extends keyof HomeStackParamList> = NativeStackScreenProps<
  HomeStackParamList,
  T
>
const HomeStackNavi = createNativeStackNavigator<HomeStackParamList>()
export const HomeStack = observer(function HomeStack() {
  return (
    <HomeStackNavi.Navigator
      screenOptions={{
        headerShown: true,
        headerTitleAlign: "left",
        headerTitle: headerTitleLogoStatic,
        headerRight: headerAccountBtn,
      }}
    >
      <HomeStackNavi.Screen name="dashboard" component={Screens.DashboardScreen} />
      <HomeStackNavi.Screen name="test" component={Screens.WIPScreen} />
      {/* IGNITE_GENERATOR_ANCHOR_APP_STACK_SCREENS */}
    </HomeStackNavi.Navigator>
  )
})

/**
 * Templates Stack Navigator
 */

export type TemplatesStackParamList = {
  templateslist: undefined
  templates: undefined
  template: undefined
  test: undefined
}
export type TemplatesStackScreenProps<T extends keyof TemplatesStackParamList> = NativeStackScreenProps<
TemplatesStackParamList,
  T
>
const TemplatesStackNavi = createNativeStackNavigator<TemplatesStackParamList>()
export const TemplateStack = observer(function TemplateStack() {
  return (
    <TemplatesStackNavi.Navigator
      screenOptions={{
        headerShown: true,
        headerTitleAlign: "left",
        headerTitle: "asd"
      }}
    >
    <TemplatesStackNavi.Screen
          name="template"
          component={Screens.TemplateScreen}
          options={{

          }}
        />

    <TemplatesStackNavi.Screen
        name="templates"
        component={Screens.TemplatesScreen}
        options={({ route, navigation }) => ({
          headerLeft: () => null,
          headerRight: () => (
            <TouchableOpacity
              onPress={() => {
                // navigate to account page
                const rootnav = navigationRef.current
                navigation.navigate("main", {
                  screen: "templateslist",
                  params: {
                    screen: "templates",
                  },
                })
              }}
            >
              <View style={{ padding: 10 }}>
                <Ionicons name="settings-outline" size={25} color="#555" />
              </View>
            </TouchableOpacity>
          ),
        })}
      />
      {/* IGNITE_GENERATOR_ANCHOR_APP_STACK_SCREENS */}
    </TemplatesStackNavi.Navigator>
  )
})

/**
 * Main Tab Navigator
 */

export type MainTabNavigatorParamList = {
  home: undefined
  docs: undefined
  add: undefined
  templates: undefined
  notes: undefined
  login: undefined
}
export type MainTabScreenProps<T extends keyof MainTabNavigatorParamList> =
  BottomTabScreenProps<MainTabNavigatorParamList>

const MainTabNavi = createBottomTabNavigator<MainTabNavigatorParamList>()
const MainTab = observer(function MainTab() {
  return (
    <>
      <MainTabNavi.Navigator screenOptions={{ headerShown: false }} initialRouteName="add">
        <MainTabNavi.Screen
          name="home"
          component={HomeStack}
          options={{
            tabBarLabel: "Home",
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="home-outline" color={color} size={size} />
            ),
          }}
        />
        <MainTabNavi.Screen
          name="notes"
          component={DataDashboardStack}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              // Prevent default action
              e.preventDefault()

              // Navigate to the notes screen and reset the stack
              navigation.navigate("notes", { screen: "datadashboard" })
            },
          })}
          options={{
            tabBarLabel: "Notes",
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="images-outline" color={color} size={size} />
            ),
          }}
        />
        <MainTabNavi.Screen
          name="add"
          component={DataAddStack}
          options={{
            tabBarLabel: "Add",
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="add-circle" color={color} size={size} />
            ),
          }}
        />
        <MainTabNavi.Screen
          name="templateslist"
          component={TemplateStack}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              // Prevent default action
              e.preventDefault()

              // Navigate to the notes screen and reset the stack
              navigation.navigate("templateslist", { screen: "templates" })
            },
          })}
          options={{
            tabBarLabel: "Notes",
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="images-outline" color={color} size={size} />
            ),
          }}
        />
        <MainTabNavi.Screen
          name="docs"
          component={Screens.WIPScreen}
          options={{
            tabBarLabel: "Docs",
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="document-text-outline" color={color} size={size} />
            ),
          }}
        />
        <MainTabNavi.Screen
          name="login"
          component={Screens.LoginScreen}
          options={{
            // hide from tab bar
            tabBarButton: () => null,
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="images-outline" color={color} size={size} />
            ),
          }}
        />
        {/* IGNITE_GENERATOR_ANCHOR_APP_STACK_SCREENS */}
      </MainTabNavi.Navigator>
    </>
  )
})

/**
 * Root Stack Navigator
 */

export type RootStackParamList = {
  login: undefined
  main: undefined
  account: undefined
  register: undefined
}
export type RootStackScreenProps<T extends keyof RootStackParamList> = NativeStackScreenProps<
  RootStackParamList,
  T
>
const RootStackNavi = createNativeStackNavigator<RootStackParamList>()
export const RootStack = observer(function RootStack() {
  return (
    <RootStackNavi.Navigator
      screenOptions={{
        //headerShown: false,
        headerTitleAlign: "left",
      }}
    >
      <RootStackNavi.Screen
        name="login"
        component={Screens.LoginScreen}
        options={{
          headerShown: false,
        }}
      />
      <RootStackNavi.Screen
        name="register"
        component={Screens.RegisterScreen}
        options={{
          headerShown: false,
        }}
      />
      <RootStackNavi.Screen
        name="main"
        component={MainTab}
        options={{
          headerShown: false,
        }}
      />
      <RootStackNavi.Screen
        name="account"
        component={Screens.AccountScreen}
        options={{
          headerTitle: "Account settings",
        }}
      />
      {/* IGNITE_GENERATOR_ANCHOR_APP_STACK_SCREENS */}
    </RootStackNavi.Navigator>
  )
})

export interface NavigationProps
  extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
  const colorScheme = useColorScheme()

  useBackButtonHandler((routeName) => exitRoutes.includes(routeName))

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    >
      <RootStack />
    </NavigationContainer>
  )
})

const styles = StyleSheet.create({
  tabBar: {
    backgroundColor: "white",
    borderTopWidth: 1,
    borderTopColor: "gray",
  },
  label: {
    fontSize: 12,
    marginBottom: 4,
  },

  logo: {
    width: 160,
    height: 70,
    resizeMode: "contain",
  },
  logoIco: {
    width: 35,
    height: 35,
    resizeMode: "contain",
  },
})
