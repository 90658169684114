import React, { useEffect } from "react"
import { StyleSheet, View } from "react-native"
import { useState } from "react"
import { colors } from "app/theme"
import { ActivityIndicator } from "react-native"
import { DataTemplate } from "app/models"
import { TouchableOpacity } from "react-native"
import { validateFaIcon } from "app/utils/uiUtils"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { Text } from "react-native"
import { Ionicons } from "@expo/vector-icons"
import Modal from "react-native-modal"
import { sub } from "date-fns"

type ConfirmModalProps = {
  show: boolean
  title: string
  subTitle?: string
  titleIcon: "warning" | "error" | "info" | "success" | "question" | "none"
  confirmText: string
  cancelText?: string
  onConfirm: () => void
  onCancel?: () => void
}
export const ConfirmModal = ({
  show,
  title,
  subTitle,
  titleIcon,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}: ConfirmModalProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  useEffect(() => {
    setShowModal(show)
  }, [show])

  return (
    <Modal isVisible={showModal}>
      <View style={styles.modalBody}>
        {titleIcon == "warning" && (
          <Ionicons name="warning-outline" size={67} color={colors.warningColor} />
        )}
        <Text style={styles.title}>{title}</Text>
        {subTitle && subTitle.length > 0 && <Text style={styles.subTitle}>{subTitle}</Text>}
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={{ ...styles.button, ...styles.cancelButton }}
            onPress={() => {
              setShowModal(false)
              if (onCancel) {
                onCancel()
              }
            }}
          >
            <Text style={{ ...styles.buttonText, ...styles.cancelButtonText }}>
              {cancelText ? cancelText : "Cancel"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ ...styles.button, ...styles.confirmButton }}
            onPress={() => {
              setShowModal(false)
              onConfirm()
            }}
          >
            <Text style={{ ...styles.buttonText, ...styles.confirmButtonText }}>{confirmText}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalBody: {
    backgroundColor: "white",
    padding: 22,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  subTitle: {
    marginBottom: 10,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  button: {
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    marginHorizontal: 5,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  cancelButton: {
    backgroundColor: "#eee",
  },
  confirmButton: {
    backgroundColor: colors.brandColor,
    color: "white",
  },
  cancelButtonText: {
    color: "#444",
  },
  confirmButtonText: {
    color: "#fff",
  },
})
