import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ActivityIndicator, Switch, ViewStyle } from "react-native"
import { DataAddStackScreenProps } from "app/navigators"
import { Screen, Text } from "app/components"
import { View } from "react-native"
import { StyleSheet } from "react-native"
import { TextInput } from "react-native"
import { TouchableOpacity } from "react-native"
import { DataGroup, DataTemplateItem, DataTemplate, File } from "app/models"
import { useState } from "react"
import { ErrorMessage } from "app/models/ErrorMessage"
import { ErrorModal } from "app/components"
import { Platform } from "react-native"
import { DataGroupPicker } from "app/components"
import { DataGroupOverview } from "app/components"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { useRef } from "react"
import { DatePickerModal } from "react-native-paper-dates"
import * as ImagePicker from "expo-image-picker"
import { Image } from "react-native"
import * as DocumentPicker from "expo-document-picker"
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator"

/**
 * This file is a mess - needs lots of refactoring
 * Known bugs:
 * - When editing a data group and data group has file fields, the file fields are not filled with previous data
 * - When editing a data group and data group has image fields, and user tries to delete the image, the image is not deleted
 */

export const DataGroupScreen: FC<DataAddStackScreenProps<"datagroup">> = observer(
  function DataGroupScreen({ navigation, route }) {
    const [error, setError] = useState<ErrorMessage>()
    const [dataGroupPickerVisible, setDataGroupPickerVisible] = useState<boolean>(false)
    const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState(true)
    const mode = useRef<"add" | "edit">("add")

    const [dataGroupPickerData, setDataGroupPickerData] = useState<{
      dataTemplateId: number
      dataTemplateItemId: number
      dataTemplateItemName: string
    }>()

    const [datePickerData, setDatePickerData] = useState<{
      dataTemplateItemId: number
      dataTemplateItemName: string
    }>()

    const [booleanToggleData, setBooleanToggleData] = useState<Map<number, boolean>>(new Map())
    const [imageData, setImageData] = useState<Map<number, string>>(new Map())

    interface FileData {
      filecontent: string
      filename: string
    }
    const [fileData, setFileData] = useState<Map<number, FileData>>(new Map())
    interface SelectedDataGroup {
      dataTemplateItemId: number
      dataGroup: DataGroup
    }
    interface Datas {
      id: number
      data: string
      name: string
      icon: string
      data_type: string
      data_selected: boolean
      description: string
      existing_data_id?: number
    }
    const [selectedDataGroups, setSelectedDataGroups] = useState<SelectedDataGroup[]>([])

    const datas = useRef<Datas[]>([])
    const dataTemplate = useRef<DataTemplate>()
    const dataTemplateItems = useRef<DataTemplateItem[]>([])

    /**
     * use effect
     */
    React.useEffect(() => {
      fetchAndSetData()
    }, [])

    /**
     * Fetch needed data for this screen
     */
    const fetchAndSetData = () => {
      // if params has dataTemplate and dataTemplateItems set them
      if (route.params.dataTemplate && route.params.dataTemplateItems) {
        console.log("params has dataTemplate and dataTemplateItems")
        dataTemplate.current = route.params.dataTemplate
        dataTemplateItems.current = route.params.dataTemplateItems
        setupDefaultViewData(route.params.dataTemplateItems)
        setLoading(false)
      } else if (route.params.dataGroup) {
        mode.current = "edit"
        // if params has dataGroup set dataTemplate and dataTemplateItems
        console.log("Fetch dataTemplate and dataTemplateItems from dataGroup")
        console.log(route.params.dataGroup.$attributes.data_template_id)
        DataTemplate.$query()
          .find(route.params.dataGroup.$attributes.data_template_id)
          .then((fetchedFataTemplate) => {
            console.log("Got data template")
            console.log(fetchedFataTemplate)
            dataTemplate.current = fetchedFataTemplate
            fetchedFataTemplate
              .dataTemplateItems()
              .get()
              .then((fetchedDataItems) => {
                dataTemplateItems.current = fetchedDataItems
                setupViewDataFromDataGroup(fetchedDataItems, route.params.dataGroup)
                console.log("READY")
                setLoading(false)
              })
          })
      }
    }

    const addNewDefaultDatas = (dataTemplateItem: DataTemplateItem) => {
      // if toggle add to booleanToggleData
      if (dataTemplateItem.$attributes.data_type == "boolean") {
        let booleanToggleDataCopy = booleanToggleData
        booleanToggleDataCopy.set(dataTemplateItem.$attributes.id, false)
        setBooleanToggleData(booleanToggleDataCopy)
      }

      return {
        id: dataTemplateItem.$attributes.id,
        data: dataTemplateItem.$attributes.data_type != "boolean" ? "" : "false",
        name: dataTemplateItem.$attributes.name,
        icon: dataTemplateItem.$attributes.icon,
        data_type: dataTemplateItem.$attributes.data_type,
        data_selected: dataTemplateItem.$attributes.data_force_select,
        description: dataTemplateItem.$attributes.description,
      }
    }

    const setupDefaultViewData = (setupDataTemplateItems: DataTemplateItem[]) => {
      let datasTemp = []
      setupDataTemplateItems.forEach((dataTemplateItem) => {
        console.log(dataTemplateItem)
        datasTemp.push(addNewDefaultDatas(dataTemplateItem))
      })
      datas.current = datasTemp
    }

    const setupViewDataFromDataGroup = (
      setupDataTemplateItems: DataTemplateItem[],
      dataGroup: DataGroup,
    ) => {
      let datasTemp = []
      setupDataTemplateItems.forEach((dataTemplateItem) => {
        console.log("Create data item from data group data")

        console.log(dataGroup.$relations.datas)
        console.log(dataTemplateItem.$attributes.id)
        console.log(setupDataTemplateItems)
        let relatedData = dataGroup.$relations.datas.find(
          (data) => data.$attributes.data_template_item_id == dataTemplateItem.$attributes.id,
        )

        if (relatedData) {
          // existing data found

          let dataToSet = relatedData.$attributes.data

          if (
            dataTemplateItem.$attributes.data_type == "image" ||
            dataTemplateItem.$attributes.data_type == "file"
          ) {
            dataToSet = ""
          }

          datasTemp.push({
            id: dataTemplateItem.$attributes.id,
            // get existing data from dataGroup
            data: dataToSet,
            name: dataTemplateItem.$attributes.name,
            icon: dataTemplateItem.$attributes.icon,
            data_type: dataTemplateItem.$attributes.data_type,
            data_selected: dataTemplateItem.$attributes.data_force_select,
            description: dataTemplateItem.$attributes.description,
            existing_data_id: relatedData.$attributes.id,
          })
          // if toggle add to booleanToggleData
          if (dataTemplateItem.$attributes.data_type == "boolean") {
            let booleanToggleDataCopy = new Map(booleanToggleData)
            booleanToggleDataCopy.set(
              dataTemplateItem.$attributes.id,
              relatedData.$attributes.data == "true",
            )
            setBooleanToggleData(booleanToggleDataCopy)
          }
          // if image add to imageData
          if (dataTemplateItem.$attributes.data_type == "image") {
            new File().getFileBase64(relatedData).then((file) => {
              let imageDataCopy = new Map(imageData)
              imageDataCopy.set(dataTemplateItem.$attributes.id, file.$attributes.data)
              setImageData(imageDataCopy)
            })
          }
        } else {
          // no existing data found
          datasTemp.push(addNewDefaultDatas(dataTemplateItem))
        }
      })
      datas.current = datasTemp

      console.log("DATAS IS NOW")
      console.log(datas.current)
    }

    /**
     * Clear data from datas array
     */
    const clearSelectionData = (dataTemplateItemId: number) => {
      let selectedDataGroupsCopy = selectedDataGroups

      // remove old data group
      selectedDataGroupsCopy = selectedDataGroupsCopy.filter(
        (data) => data.dataTemplateItemId != dataTemplateItemId,
      )
      setSelectedDataGroups(selectedDataGroupsCopy)
    }

    /**
     * Update data in datas array
     * @param dataTemplateItemId
     * @param data
     * @param data_selected_data_group_id
     */
    const setData = (
      dataTemplateItemId: number,
      data?: string,
      data_selected_data_group?: DataGroup,
    ) => {
      console.log("SETDATA")

      // find index of item in datas array where id == dataTemplateItemId
      if (data_selected_data_group) {
        let selectedDataGroupsCopy = selectedDataGroups
        selectedDataGroupsCopy = selectedDataGroupsCopy.filter(
          (data) => data.dataTemplateItemId != dataTemplateItemId,
        )
        selectedDataGroupsCopy.push({
          dataTemplateItemId: dataTemplateItemId,
          dataGroup: data_selected_data_group,
        })
        setSelectedDataGroups(selectedDataGroupsCopy)
      } else {
        console.log("SET NORMAL DATA")
        console.log(data)
        console.log("current datas is")
        console.log(datas.current)
        // update one item inside datas array
        let datasCopy = datas.current
        let index = datasCopy.findIndex((data) => data.id == dataTemplateItemId)
        console.log(index)
        console.log(dataTemplateItemId)
        datasCopy[index].data = data
        datas.current = datasCopy
      }
    }

    /**
     * Send data items to backend
     * @param dataGroup
     * @returns
     */
    const uploadDatas = (dataGroup: DataGroup) => {
      console.log("uploadDatasstarted")
      console.log(dataGroup)
      console.log(datas)
      let dataUploads = []
      datas.current.forEach((dataItm) => {
        console.log("DATAAA")
        console.log(dataItm)
        if (dataItm.data_selected) {
          console.log("UPLOADING selected")
          let selectedDataGroup = selectedDataGroups.find(
            (dataGroup) => dataGroup.dataTemplateItemId == dataItm.id,
          )
          if (selectedDataGroup) {
            console.log(selectedDataGroup.dataGroup.$attributes.id)
            dataUploads.push(
              dataGroup.datas().store({
                data_template_item_id: dataItm.id,
                data_group_id: dataGroup.$attributes.id,
                name: dataItm.name,
                data_type: dataItm.data_type,
                data_selected: dataItm.data_selected,
                data_selected_data_group_id: selectedDataGroup.dataGroup.$attributes.id,
                data: selectedDataGroup.dataGroup.$attributes.id.toString(),
              }),
            )
          }
        } else if (dataItm.data != "") {
          console.log("UPLOADING normal")
          dataUploads.push(
            dataGroup.datas().store({
              data_template_item_id: dataItm.id,
              data: dataItm.data,
              data_type: dataItm.data_type,
              data_group_id: dataGroup.$attributes.id,
              name: dataItm.name,
              data_selected: dataItm.data_selected,
            }),
          )
        } else if (dataItm.data_type == "image") {
          if (imageData.get(dataItm.id) != undefined) {
            console.log("UPLOADING image")
            dataUploads.push(
              dataGroup.datas().store({
                data_template_item_id: dataItm.id,
                file_data: imageData.get(dataItm.id),
                data: "image",
                data_type: dataItm.data_type,
                data_group_id: dataGroup.$attributes.id,
                name: dataItm.name,
                data_selected: dataItm.data_selected,
              }),
            )
          }
        } else if (dataItm.data_type == "file") {
          if (fileData.get(dataItm.id) != undefined) {
            console.log("UPLOADING file")
            dataUploads.push(
              dataGroup.datas().store({
                data_template_item_id: dataItm.id,
                file_data: fileData.get(dataItm.id).filecontent,
                data_file_name: fileData.get(dataItm.id).filename,
                data: "file",
                data_type: dataItm.data_type,
                data_group_id: dataGroup.$attributes.id,
                name: dataItm.name,
                data_selected: dataItm.data_selected,
              }),
            )
          }
        }
      })
      return Promise.all(dataUploads)
    }

    /**
     * Send updated data items to backend
     * @param dataGroup
     * @returns
     */
    const updateDatas = (dataGroup: DataGroup) => {
      console.log("DATA UPDATE")
      console.log(dataGroup)
      console.log(datas)
      let dataUploads = []
      datas.current.forEach((dataItm) => {
        console.log("DATAAA")
        console.log(dataItm)
        if (dataItm.data_selected) {
          console.log("UPLOADING selected")
          let selectedDataGroup = selectedDataGroups.find(
            (dataGroup) => dataGroup.dataTemplateItemId == dataItm.id,
          )
          if (selectedDataGroup) {
            console.log(selectedDataGroup.dataGroup.$attributes.id)
            if (dataItm.existing_data_id) {
              dataUploads.push(
                dataGroup.datas().update(dataItm.existing_data_id, {
                  data_template_item_id: dataItm.id,
                  data_group_id: dataGroup.$attributes.id,
                  name: dataItm.name,
                  data_type: dataItm.data_type,
                  data_selected: dataItm.data_selected,
                  data_selected_data_group_id: selectedDataGroup.dataGroup.$attributes.id,
                  data: selectedDataGroup.dataGroup.$attributes.id.toString(),
                }),
              )
            } else {
              dataUploads.push(
                dataGroup.datas().store({
                  data_template_item_id: dataItm.id,
                  data_group_id: dataGroup.$attributes.id,
                  name: dataItm.name,
                  data_type: dataItm.data_type,
                  data_selected: dataItm.data_selected,
                  data_selected_data_group_id: selectedDataGroup.dataGroup.$attributes.id,
                  data: selectedDataGroup.dataGroup.$attributes.id.toString(),
                }),
              )
            }
          }
        } else if (dataItm.data != "") {
          console.log("UPLOADING normal")

          if (dataItm.existing_data_id) {
            dataUploads.push(
              dataGroup.datas().update(dataItm.existing_data_id, {
                data_template_item_id: dataItm.id,
                data: dataItm.data,
                data_type: dataItm.data_type,
                data_group_id: dataGroup.$attributes.id,
                name: dataItm.name,
                data_selected: dataItm.data_selected,
              }),
            )
          } else {
            dataUploads.push(
              dataGroup.datas().store({
                data_template_item_id: dataItm.id,
                data: dataItm.data,
                data_type: dataItm.data_type,
                data_group_id: dataGroup.$attributes.id,
                name: dataItm.name,
                data_selected: dataItm.data_selected,
              }),
            )
          }
        } else if (dataItm.data_type == "image") {
          if (imageData.get(dataItm.id) != undefined) {
            console.log("UPLOADING image")

            if (dataItm.existing_data_id) {
              dataUploads.push(
                dataGroup.datas().update(dataItm.existing_data_id, {
                  data_template_item_id: dataItm.id,
                  file_data: imageData.get(dataItm.id),
                  data: "image",
                  data_type: dataItm.data_type,
                  data_group_id: dataGroup.$attributes.id,
                  name: dataItm.name,
                  data_selected: dataItm.data_selected,
                }),
              )
            } else {
              dataUploads.push(
                dataGroup.datas().store({
                  data_template_item_id: dataItm.id,
                  file_data: imageData.get(dataItm.id),
                  data: "image",
                  data_type: dataItm.data_type,
                  data_group_id: dataGroup.$attributes.id,
                  name: dataItm.name,
                  data_selected: dataItm.data_selected,
                }),
              )
            }
          }
        } else if (dataItm.data_type == "file") {
          if (fileData.get(dataItm.id) != undefined) {
            console.log("UPLOADING file")

            if (dataItm.existing_data_id) {
              dataUploads.push(
                dataGroup.datas().update(dataItm.existing_data_id, {
                  data_template_item_id: dataItm.id,
                  file_data: fileData.get(dataItm.id).filecontent,
                  data_file_name: fileData.get(dataItm.id).filename,
                  data: "file",
                  data_type: dataItm.data_type,
                  data_group_id: dataGroup.$attributes.id,
                  name: dataItm.name,
                  data_selected: dataItm.data_selected,
                }),
              )
            } else {
              dataUploads.push(
                dataGroup.datas().store({
                  data_template_item_id: dataItm.id,
                  file_data: fileData.get(dataItm.id).filecontent,
                  data_file_name: fileData.get(dataItm.id).filename,
                  data: "file",
                  data_type: dataItm.data_type,
                  data_group_id: dataGroup.$attributes.id,
                  name: dataItm.name,
                  data_selected: dataItm.data_selected,
                }),
              )
            }
          }
        }
      })
      return Promise.all(dataUploads)
    }

    /**
     * Show data group picker
     */
    const showDataGroupPicker = (dataTemplateItem: DataTemplateItem) => () => {
      setDataGroupPickerData({
        dataTemplateId: dataTemplateItem.$attributes.data_select_from_data_template.id,
        dataTemplateItemId: dataTemplateItem.$attributes.id,
        dataTemplateItemName: dataTemplateItem.$attributes.data_select_from_data_template.name,
      })

      setDataGroupPickerVisible(true)
    }

    /**
     * Show date picker
     */
    const showDatePicker = (dataTemplateItem: DataTemplateItem) => () => {
      setDatePickerData({
        dataTemplateItemId: dataTemplateItem.$attributes.id,
        dataTemplateItemName: dataTemplateItem.$attributes.name,
      })

      setDatePickerVisible(true)
    }

    /**
     * Image picker
     */
    const pickImage = async (dataTemplateItemId) => {
      console.log("PICK")
      console.log(dataTemplateItemId)
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      })

      console.log(result)

      if (!result.canceled) {
        // update img data in imageData
        let imageDataCopy = new Map(imageData)
        imageDataCopy.set(dataTemplateItemId, result.assets[0].uri)
        setImageData(imageDataCopy)
        console.log("imageDataCopy")
        console.log(imageDataCopy)
      }
    }

    /**
     * File picker
     */
    const pickFile = async (dataTemplateItemId) => {
      console.log("PICK file")
      console.log(dataTemplateItemId)

      try {
        const result = await DocumentPicker.getDocumentAsync()
        if (result.type === "success") {
          // You can use `result.uri` to access the file URI
          // Update the file data in `fileData` state
          let fileDataCopy = new Map(fileData)
          fileDataCopy.set(dataTemplateItemId, {
            filecontent: result.uri,
            filename: result.name,
          })
          setFileData(fileDataCopy)
          console.log("file set")
        }
      } catch (error) {
        console.log("Error picking file:", error)
      }
      // No permissions request is necessary for launching the image library
      // ;async () => {
      //   try {
      //     const pickerResult = await DocumentPicker.pickSingle({
      //       presentationStyle: "fullScreen",
      //       copyTo: "cachesDirectory",
      //     })
      //     console.log([pickerResult])
      //   } catch (e) {}
      // }

      // console.log(result)

      // if (!result.canceled) {
      //   // update img data in imageData
      //   let imageDataCopy = new Map(imageData)
      //   imageDataCopy.set(dataTemplateItemId, result.assets[0].uri)
      //   setImageData(imageDataCopy)
      //   console.log("imageDataCopy")
      //   console.log(imageDataCopy)
      // }
    }

    /**
     * Handle save button press
     */
    const handleSavePress = () => {
      if (mode.current == "add") {
        const dataGroup = DataGroup.$query()
          .store({
            data_template_id: dataTemplate.current.$attributes.id,
            name: dataTemplate.current.$attributes.name,
            icon: dataTemplate.current.$attributes.icon,
          })
          .then((dataGroup) => {
            uploadDatas(dataGroup)
              .then(() => {
                navigation.navigate("datatemplates", {
                  notification: dataTemplate.current.$attributes.name + " saved successfully",
                })
              })
              .catch((error) => {
                setError(ErrorMessage.fromException(error, "DataGroup"))
              })
          })
          .catch((error) => {
            setError(ErrorMessage.fromException(error, "DataGroup"))
          })
      } else {
        updateDatas(route.params.dataGroup)
          .then(() => {
            console.log("GO BACK")
            navigation.goBack()
          })
          .catch((error) => {
            setError(ErrorMessage.fromException(error, "Data Edit"))
          })
      }
    }

    return (
      <View style={{ flex: 1 }}>
        <Screen style={$root} preset="scroll">
          {loading ? (
            <ActivityIndicator size="large" />
          ) : (
            <View>
              <ErrorModal
                error={error}
                onDismiss={() => {}}
                dismissText="Ok"
                tryAgainVisible={true}
                onTryAgain={handleSavePress}
                tryAgainText="Try again"
              />
              {dataGroupPickerData && (
                <DataGroupPicker
                  title={"Select " + dataGroupPickerData.dataTemplateItemName}
                  isModalVisible={dataGroupPickerVisible}
                  dataTemplateId={dataGroupPickerData.dataTemplateId}
                  onCancel={() => {
                    setDataGroupPickerVisible(false)
                  }}
                  onSelect={(selectedDataGroup) => {
                    setDataGroupPickerVisible(false)
                    setData(dataGroupPickerData.dataTemplateItemId, undefined, selectedDataGroup)
                  }}
                ></DataGroupPicker>
              )}
              {datePickerData && (
                <DatePickerModal
                  locale="en-GB"
                  visible={datePickerVisible}
                  onDismiss={() => {
                    setDatePickerVisible(false)
                  }}
                  onConfirm={(data) => {
                    setDatePickerVisible(false)
                    setData(datePickerData.dataTemplateItemId, data.date.toISOString().slice(0, 10))
                  }}
                  mode="single"
                />
              )}

              <View style={styles.inputsContainer}>
                {dataTemplateItems.current.map((dataTemplateItem) => (
                  <View key={dataTemplateItem.$attributes.id} style={styles.SectionStyle}>
                    {dataTemplateItem.$attributes.data_type == "text" ? (
                      <View>
                        <View style={{ justifyContent: "center" }}>
                          <Text style={{ textAlign: "center" }}>
                            {dataTemplateItem.$attributes.name}
                          </Text>
                        </View>
                        <View>
                          <TextInput
                            style={styles.inputStyle}
                            onChangeText={(data) => setData(dataTemplateItem.$attributes.id, data)}
                            placeholder={dataTemplateItem.$attributes.description}
                            placeholderTextColor="#8b9cb5"
                            keyboardType="default"
                            onSubmitEditing={() => {}}
                            underlineColorAndroid="#f000"
                            returnKeyType="next"
                            defaultValue={
                              datas.current.find(
                                (data) => data.id == dataTemplateItem.$attributes.id,
                              ).data
                            }
                          />
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                    {dataTemplateItem.$attributes.data_type == "number" ? (
                      <View>
                        <View style={{ justifyContent: "center" }}>
                          <Text style={{ textAlign: "center" }}>
                            {dataTemplateItem.$attributes.name}
                          </Text>
                        </View>
                        <View>
                          <TextInput
                            style={styles.inputStyle}
                            onChangeText={(data) => setData(dataTemplateItem.$attributes.id, data)}
                            placeholder={dataTemplateItem.$attributes.description}
                            placeholderTextColor="#8b9cb5"
                            keyboardType={Platform.OS == "android" ? "numeric" : "number-pad"}
                            onSubmitEditing={() => {}}
                            underlineColorAndroid="#f000"
                            returnKeyType="next"
                            defaultValue={
                              datas.current.find(
                                (data) => data.id == dataTemplateItem.$attributes.id,
                              ).data
                            }
                          />
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                    {dataTemplateItem.$attributes.data_type == "boolean" ? (
                      <View>
                        <View style={{ justifyContent: "center" }}>
                          <Text style={{ textAlign: "center" }}>
                            {dataTemplateItem.$attributes.name}
                          </Text>
                        </View>
                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                          <Switch
                            style={{ marginTop: 9, transform: [{ scaleX: 1.6 }, { scaleY: 1.6 }] }}
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={
                              booleanToggleData.get(dataTemplateItem.$attributes.id)
                                ? "#f5dd4b"
                                : "#f4f3f4"
                            }
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={(newValue) => {
                              let booleanToggleDataCopy = new Map(booleanToggleData)
                              booleanToggleDataCopy.set(dataTemplateItem.$attributes.id, newValue)
                              setBooleanToggleData(booleanToggleDataCopy)
                              setData(
                                dataTemplateItem.$attributes.id,
                                booleanToggleDataCopy
                                  .get(dataTemplateItem.$attributes.id)
                                  .toString(),
                              )
                            }}
                            value={booleanToggleData.get(dataTemplateItem.$attributes.id)}
                          />
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}

                    {dataTemplateItem.$attributes.data_type == "image" ? (
                      <View>
                        <View style={{ justifyContent: "center" }}>
                          <Text style={{ textAlign: "center" }}>
                            {dataTemplateItem.$attributes.name}
                          </Text>
                        </View>
                        <View>
                          {imageData.get(dataTemplateItem.$attributes.id) ? (
                            <View style={{ alignItems: "center" }}>
                              <View style={{ height: 200, width: 200 }}>
                                <Image
                                  source={{ uri: imageData.get(dataTemplateItem.$attributes.id) }}
                                  style={{ resizeMode: "contain", height: 200, width: 200 }}
                                />
                                <TouchableOpacity
                                  style={{ position: "absolute", right: -10, top: -10 }}
                                  activeOpacity={0.5}
                                  onPress={() => {
                                    // remove image from imageData
                                    let imageDataCopy = new Map(imageData)
                                    imageDataCopy.delete(dataTemplateItem.$attributes.id)
                                    setImageData(imageDataCopy)
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={"fa-solid fa-circle-xmark" as IconName}
                                    size={25}
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                          ) : (
                            <TouchableOpacity
                              style={styles.selectButtonStyle}
                              onPress={() => {
                                pickImage(dataTemplateItem.$attributes.id)
                              }}
                            >
                              <Text style={styles.selectButtonTextStyle}>Select image</Text>
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}

                    {dataTemplateItem.$attributes.data_type == "file" ? (
                      <View>
                        <View style={{ justifyContent: "center" }}>
                          <Text style={{ textAlign: "center" }}>
                            {dataTemplateItem.$attributes.name}
                          </Text>
                        </View>
                        <View>
                          {fileData.get(dataTemplateItem.$attributes.id) ? (
                            <View>
                              <View style={styles.fileBox}>
                                <Text>
                                  {fileData.get(dataTemplateItem.$attributes.id).filename}
                                </Text>
                                <TouchableOpacity
                                  style={{ position: "absolute", right: -10, top: -10 }}
                                  activeOpacity={0.5}
                                  onPress={() => {
                                    let fileDataCopy = new Map(fileData)
                                    fileDataCopy.delete(dataTemplateItem.$attributes.id)
                                    setFileData(fileDataCopy)
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={"fa-solid fa-circle-xmark" as IconName}
                                    size={25}
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                          ) : (
                            <TouchableOpacity
                              style={styles.selectButtonStyle}
                              onPress={() => {
                                pickFile(dataTemplateItem.$attributes.id)
                              }}
                            >
                              <View>
                                <Text style={styles.selectButtonTextStyle}>Select file</Text>
                              </View>
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                    {dataTemplateItem.$attributes.data_type == "date" ? (
                      <View>
                        <View style={{ justifyContent: "center" }}>
                          <Text style={{ textAlign: "center" }}>
                            {dataTemplateItem.$attributes.name}
                          </Text>
                        </View>
                        <View>
                          {datas.current.find(
                            (data) => data.id == dataTemplateItem.$attributes.id,
                          ) != undefined &&
                          datas.current.find((data) => data.id == dataTemplateItem.$attributes.id)
                            .data != "" ? (
                            <View
                              style={{
                                borderWidth: 1,
                                borderColor: "#aaa",
                                backgroundColor: "#fff",
                                paddingVertical: 8,
                                flexDirection: "row",
                                marginHorizontal: 20,
                                paddingLeft: 15,
                                paddingRight: 7,
                              }}
                            >
                              <Text style={{ textAlign: "left", flex: 1 }}>
                                {
                                  datas.current.find(
                                    (data) => data.id == dataTemplateItem.$attributes.id,
                                  ).data
                                }
                              </Text>
                              <TouchableOpacity
                                style={{}}
                                activeOpacity={0.5}
                                onPress={() => {
                                  setData(dataTemplateItem.$attributes.id, "")
                                  setDatePickerData(undefined)
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={"fa-solid fa-circle-xmark" as IconName}
                                  size={25}
                                />
                              </TouchableOpacity>
                            </View>
                          ) : (
                            <TouchableOpacity
                              style={styles.selectButtonStyle}
                              activeOpacity={0.5}
                              onPress={
                                showDatePicker(dataTemplateItem)
                                //dataTemplateItem.$attributes.data_select_from_data_template,
                              }
                            >
                              <Text style={styles.selectButtonTextStyle}>Select date</Text>
                            </TouchableOpacity>
                          )}
                          {/* <TextInput
                        style={styles.inputStyle}
                        onChangeText={(data) => setData(dataTemplateItem.$attributes.id, data)}
                        placeholder={dataTemplateItem.$attributes.description}
                        placeholderTextColor="#8b9cb5"
                        keyboardType={Platform.OS == "android" ? "numeric" : "number-pad"}
                        onSubmitEditing={() => {}}
                        underlineColorAndroid="#f000"
                        returnKeyType="next"
                      /> */}
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                    {dataTemplateItem.$attributes.data_force_select ? (
                      <View>
                        {dataTemplateItem.$attributes.data_type == "select" ? (
                          <View>
                            <View style={{ justifyContent: "center" }}>
                              <Text style={{ textAlign: "center" }}>
                                {dataTemplateItem.$attributes.name}
                              </Text>
                            </View>
                            <View>
                              {selectedDataGroups.find(
                                (data) =>
                                  data.dataTemplateItemId == dataTemplateItem.$attributes.id,
                              ) ? (
                                <View>
                                  <DataGroupOverview
                                    dataGroup={
                                      selectedDataGroups.find(
                                        (data) =>
                                          data.dataTemplateItemId ==
                                          dataTemplateItem.$attributes.id,
                                      ).dataGroup
                                    }
                                  >
                                    <TouchableOpacity
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        top: -10,
                                      }}
                                      activeOpacity={0.5}
                                      onPress={() => {
                                        clearSelectionData(dataTemplateItem.$attributes.id)
                                      }}
                                    >
                                      <Text style={styles.buttonTextStyle}>
                                        <FontAwesomeIcon
                                          icon={"fa-solid fa-circle-xmark" as IconName}
                                          size={25}
                                        />
                                      </Text>
                                    </TouchableOpacity>
                                  </DataGroupOverview>
                                </View>
                              ) : (
                                <TouchableOpacity
                                  style={styles.selectButtonStyle}
                                  activeOpacity={0.5}
                                  onPress={
                                    showDataGroupPicker(dataTemplateItem)
                                    //dataTemplateItem.$attributes.data_select_from_data_template,
                                  }
                                >
                                  <Text style={styles.selectButtonTextStyle}>
                                    Select{" "}
                                    {
                                      dataTemplateItem.$attributes.data_select_from_data_template
                                        .name
                                    }
                                  </Text>
                                </TouchableOpacity>
                              )}
                            </View>
                          </View>
                        ) : (
                          <></>
                        )}
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>
                ))}
              </View>
            </View>
          )}
        </Screen>
        <View style={{ justifyContent: "flex-end" }}>
          <TouchableOpacity
            style={styles.buttonStyle}
            activeOpacity={0.5}
            onPress={handleSavePress}
          >
            <Text style={styles.buttonTextStyle}>Save</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  },
)

const $root: ViewStyle = {
  flex: 1,
}

const styles = StyleSheet.create({
  inputsContainer: {},
  dataTemplateButton: {
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  inputStyle: {
    flex: 1,
    color: "black",
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    paddingTop: 10,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: "#dadae8",
    backgroundColor: "#FFFFFF",
  },

  SectionStyle: {
    margin: 10,
    marginTop: 20,
  },

  buttonStyle: {
    backgroundColor: "#7DE24E",
    borderWidth: 0,
    color: "#FFFFFF",
    borderColor: "#7DE24E",
    height: 40,
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 15,
  },
  buttonTextStyle: {
    color: "#FFFFFF",
    paddingVertical: 10,
    fontSize: 16,
  },

  selectButtonStyle: {
    backgroundColor: "#fff",
    color: "#FFFFFF",
    borderColor: "#aaa",
    alignItems: "center",
    borderRadius: 0,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 15,
    borderWidth: 2,
    borderStyle: "dashed",
  },
  selectButtonTextStyle: {
    color: "#000",
    paddingVertical: 10,
    fontSize: 16,
  },
  fileBox: {
    color: "#000",
    borderColor: "#aaa",
    alignItems: "center",
    borderRadius: 0,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 15,
    borderWidth: 2,
    paddingVertical: 10,
    fontSize: 16,
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
})
