import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View } from "react-native"
import { DataDashboardStackScreenProps } from "app/navigators"
import { DataGroupOverview, Screen, Text } from "app/components"
import { ActivityIndicator, Platform } from "react-native"
import { useState } from "react"
import { DataTemplate } from "app/models/DataTemplate"
import { ScrollView } from "react-native-gesture-handler"
import { TouchableOpacity } from "react-native"
import { StyleSheet } from "react-native"
import { colors } from "app/theme"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { DataGroup } from "app/models"
import { ErrorModal } from "app/components"
import { ErrorMessage } from "app/models/ErrorMessage"
import { useFocusEffect } from "@react-navigation/native"
import { SortDirection } from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection"
import moment from "moment"
import { Divider } from "@rneui/base"
import { Col, Row, Grid } from "react-native-easy-grid"
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator"
import { validateFaIcon } from "app/utils/uiUtils"

export const DataDashboardScreen: FC<DataDashboardStackScreenProps<"datadashboard">> = observer(
  function DataDashboardScreen({ navigation, route }) {
    const [dataGroups, setDataGroups] = useState<DataGroup[]>([])
    const [dataTemplates, setDataTemplates] = useState<DataTemplate[]>([])
    const [typeFilter, setTypeFilter] = useState<DataTemplate>()
    const [dataTemplatesLoading, setDataTemplatesLoading] = useState(true)
    const [dataGroupsLoading, setDataGroupsLoading] = useState(true)
    const [error, setError] = useState<ErrorMessage>()
    let dataFeched = 0

    const errorModalDismissed = (): void => {
      navigation.goBack()
    }

    useFocusEffect(
      React.useCallback(() => {
        fetchData()
      }, []),
    )

    /**
     * User selected a data template to filter the data groups by - update the filter
     * @param dataTemplate
     * @returns
     */
    const updateFilter = (dataTemplate): void => {
      setDataGroupsLoading(true)
      // if same filter is selected, remove filter
      if (dataTemplate.$attributes.id == typeFilter?.$attributes.id) {
        setTypeFilter(undefined)
        fetchData(false)
        return
      }

      setTypeFilter(dataTemplate)

      DataGroup.$query()
        .filter("data_template_id", FilterOperator.Equal, dataTemplate.$attributes.id)
        .sortBy("created_at", SortDirection.Desc)
        .search()
        .then((response) => {
          setDataGroups(response)
          setDataGroupsLoading(false)
        })
        .catch((error) => {
          setError(ErrorMessage.fromException(error, "DataGroup"))
          setDataGroupsLoading(false)
        })
    }

    /**
     * Fetch data groups and data templates
     * @param fetchDataTemplates
     */
    async function fetchData(fetchDataTemplates = true) {
      setDataGroupsLoading(true)
      if (fetchDataTemplates) {
        setDataTemplatesLoading(true)
      }

      DataGroup.$query()
        .sortBy("created_at", SortDirection.Desc)
        .search()
        .then((response) => {
          setDataGroups(response)
          setDataGroupsLoading(false)
        })
        .catch((error) => {
          setError(ErrorMessage.fromException(error, "DataGroup"))
          setDataGroupsLoading(false)
        })
      if (fetchDataTemplates) {
        DataTemplate.$query()
          .sortBy("last_used_at", SortDirection.Desc)
          .search(100)
          .then((response) => {
            setDataTemplates(response)
            setDataTemplatesLoading(false)
          })
          .catch((error) => {
            setError(ErrorMessage.fromException(error, "DataGroup"))
            setDataTemplatesLoading(false)
          })
      }
    }

    /**
     * User selected a data group -> navigate to data group screen
     */
    const dataGroupSelected = (dataGroup: DataGroup): void => {
      navigation.navigate("editdatagroup", { dataGroup: dataGroup })
    }

    return (
      <Screen style={styles.mainBody} preset="scroll">
        <ErrorModal
          error={error}
          onDismiss={errorModalDismissed}
          dismissText="Ok"
          tryAgainVisible={true}
          onTryAgain={fetchData}
          tryAgainText="Try again"
        />

        <View style={{ marginTop: 10, paddingTop: 8 }}>
          <View style={{ marginHorizontal: 10 }}>
            <Text style={styles.title}>Filter by type</Text>

            {dataTemplatesLoading ? (
              <ActivityIndicator style={styles.loading} size="large" color={colors.brandColor} />
            ) : (
              <ScrollView horizontal={true} style={{ alignContent: "center" }}>
                {dataTemplates &&
                  dataTemplates.map((dataTemplate, index) => (
                    <TouchableOpacity
                      key={dataTemplate.$attributes.id}
                      style={{
                        justifyContent: "center",
                        borderRadius: 10,
                        padding: 10,
                        borderWidth: 1,
                        borderColor: colors.cardBorder,
                        backgroundColor: "#fff",
                        borderTopStartRadius: index == 0 ? 10 : 0,
                        borderBottomStartRadius: index == 0 ? 10 : 0,
                        borderRightWidth: index == dataTemplates.length - 1 ? 1 : 0,
                        borderTopEndRadius: index == dataTemplates.length - 1 ? 10 : 0,
                        borderBottomEndRadius: index == dataTemplates.length - 1 ? 10 : 0,
                        minWidth: 60,
                        marginBottom: 10,
                      }}
                      activeOpacity={1}
                      onPress={() => {
                        updateFilter(dataTemplate)
                      }}
                    >
                      <View
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: 5,
                          borderTopRightRadius: 10,
                          borderBottomLeftRadius: 10,
                        }}
                      >
                        {dataTemplate.$attributes.id == typeFilter?.$attributes.id ? (
                          <FontAwesomeIcon icon="check" size={20} color="#000" />
                        ) : (
                          // <FontAwesomeIcon icon="xmark" size={20} color="#000" />
                          <></>
                        )}
                      </View>
                      <Text style={{ textAlign: "center", borderWidth: 0 }}>
                        <FontAwesomeIcon
                          icon={validateFaIcon(dataTemplate.$attributes.icon)}
                          size={20}
                          color={
                            dataTemplate.$attributes.id == typeFilter?.$attributes.id ||
                            typeFilter?.$attributes.id == undefined
                              ? "#000"
                              : "#999"
                          }
                          style={{ outlineStyle: "none" }} // Remove the outline on the icon - causes warning but still works in web??
                        />
                      </Text>
                      <Text
                        style={{
                          textAlign: "center",
                          color:
                            dataTemplate.$attributes.id == typeFilter?.$attributes.id ||
                            typeFilter?.$attributes.id == undefined
                              ? "#000"
                              : "#999",
                        }}
                      >
                        {dataTemplate.$attributes.name}
                      </Text>
                    </TouchableOpacity>
                  ))}
              </ScrollView>
            )}
          </View>
          <View style={{ marginBottom: 10, marginTop: 15 }}>
            <Text style={styles.title}>Latest notes</Text>
            {dataGroupsLoading ? (
              <ActivityIndicator style={styles.loading} size="large" color={colors.brandColor} />
            ) : (
              <View style={styles.btnListContainer}>
                {dataGroups &&
                  dataGroups.map((dataGroup) => (
                    <DataGroupOverview
                      key={dataGroup.$attributes.id}
                      dataGroup={dataGroup}
                      onSelect={dataGroupSelected}
                    ></DataGroupOverview>
                  ))}
              </View>
            )}
          </View>
        </View>
      </Screen>
    )
  },
)

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    backgroundColor: colors.background,
    alignContent: "center",
  },
  btnContainer: {},
  dataTemplateButton: {
    flex: 1,
    borderColor: colors.cardBorder,
    borderWidth: 1,
    margin: 10,
    marginBottom: 0,
    textAlign: "right",
    borderRadius: 10,
    backgroundColor: colors.card,
  },
  loading: {
    marginTop: 20,
  },
  btnListContainer: {},
  btnico: {
    fontSize: 40,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10,
    color: colors.text,
  },
  email: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
    color: colors.text,
  },
  title2: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 20,
    color: colors.text,
  },
  code: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 40,
    color: colors.text,
    borderWidth: 1,
    borderColor: colors.text,
    borderRadius: 10,
    marginLeft: 35,
    marginRight: 35,
    padding: 5,
    marginTop: 10,
  },
  SectionStyle: {
    flexDirection: "row",
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: "#7DE24E",
    borderWidth: 0,
    color: "#FFFFFF",
    borderColor: "#7DE24E",
    fontWeight: "bold",
    height: 40,
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 0,
    marginBottom: 10,
  },
  buttonTextStyle: {
    color: "#FFFFFF",
    fontWeight: "bold",
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: "white",
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: "#dadae8",
  },
  registerTextStyle: {
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    alignSelf: "center",
    padding: 10,
  },
  errorTextStyle: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },
})
