import { Model } from "@tailflow/laravel-orion/lib/model"
import { DataTemplate } from "./DataTemplate"

export class DataTemplateItem extends Model<{
  name?: string
  id?: number
  icon?: string
  data_type?: string
  description?: string
  data_template_id?: number
  data_force_select?: boolean
  data_select_from_data_template_id?: number
  data_select_from_data_template?: {
    id: number
    name: string
    icon: string
  }
}> {
  public $resource(): string {
    return "data_template_items"
  }

  public static availableStandaloneDataTypes(): Array<string> {
    return ["text", "number", "date", "boolean", "image", "file"]
  }

  public static availableLinkedDataTypes(): Array<string> {
    return ["select"]
  }
}
