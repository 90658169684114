import React, { useEffect } from "react"
import { StyleSheet, View, Text, Button } from "react-native"
import RNModal from "react-native-modal"
import { Modal } from "./Modal"
import { ErrorMessage } from "app/models/ErrorMessage"
import { useState } from "react"
import { useFocusEffect } from "@react-navigation/native"

type ErrorModalProps = {
  isModalVisible?: boolean
  error: ErrorMessage
  onDismiss: () => void
  dismissText: string
  tryAgainVisible?: boolean
  onTryAgain?: () => void
  tryAgainText?: string
}
export const ErrorModal = ({
  isModalVisible = true,
  error,
  onDismiss,
  dismissText,
  tryAgainVisible,
  onTryAgain,
  tryAgainText,
}: ErrorModalProps) => {
  const [modalVisible, setModalVisible] = useState(isModalVisible)

  const hide = () => {
    setModalVisible(false)
  }

  useEffect(() => {
    setModalVisible(true)
  }, [error])
  return (
    error && (
      <View>
        <Modal isVisible={modalVisible}>
          <Modal.Container>
            <Modal.Header title={error.title} />
            <Modal.Body>
              <Text style={styles.text}>{error.message}</Text>
            </Modal.Body>
            <Modal.Footer>
              <Button title={dismissText} onPress={() => (hide(), onDismiss())} />
              {tryAgainVisible && (
                <Button title={tryAgainText} onPress={() => (hide(), onTryAgain())} />
              )}
            </Modal.Footer>
          </Modal.Container>
        </Modal>
      </View>
    )
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 25,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    paddingTop: 10,
    textAlign: "center",
    fontSize: 24,
  },
  body: {
    justifyContent: "center",
    paddingHorizontal: 15,
    minHeight: 100,
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    flexDirection: "row",
  },
})
