import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { DataAddStackScreenProps } from "app/navigators"
import { Screen, Text } from "app/components"
import { View } from "react-native"
import { StyleSheet } from "react-native"
import { TextInput } from "react-native"
import { TouchableOpacity } from "react-native"
import { useState } from "react"
import { ErrorMessage } from "app/models/ErrorMessage"
import { ErrorModal } from "app/components"
import { Divider } from "@rneui/themed"
import { DataTemplate, DataTemplateItem } from "app/models"
import { IconPicker } from "app/components"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { DataTypePicker } from "app/components/DataTypePicker"
import { validateFaIcon } from "app/utils/uiUtils"
import { TxKeyPath } from "app/i18n"
import { Ionicons } from "@expo/vector-icons"
import { useRef } from "react"

export const DataTemplateScreen: FC<DataAddStackScreenProps<"datagroup">> = observer(
  function DataTemplateScreen({ navigation, route }) {
    const deletedDataTemplateItems = useRef<number[]>([])
    const mode = useRef<"create" | "edit">("create")
    const existingDataTemplate = useRef<DataTemplate>()
    const existingDataTemplateItems = useRef<DataTemplateItem[]>()
    const [error, setError] = useState<ErrorMessage>()
    const [showIconPicker, setShowIconPicker] = useState<boolean>(false)
    const [showTypePicker, setShowTypePicker] = useState<boolean>(false)
    interface DataTemplateItemData {
      id: number
      data_type: string
      name: string
      data_force_select: boolean
      data_select_from_data_template?: DataTemplate
    }
    interface DataTemplateData {
      name: string
      icon: string
      description: string
    }
    const [dataTemplateItems, setDataTemplateItems] = useState<DataTemplateItemData[]>([])
    const [dataTemplateData, setDataTemplateData] = useState<DataTemplateData>({
      name: "",
      icon: "",
      description: "",
    })

    useEffect(() => {
      // Set the initial state if we are editing an existing data template
      if (route.params?.dataTemplate) {
        mode.current = "edit"
        existingDataTemplate.current = route.params.dataTemplate
        fetchData(route.params.dataTemplate)
      }
    }, [])

    /**
     * Fetch data
     */
    const fetchData = async (dataTemplate: DataTemplate) => {
      console.log("Fetching data template")
      setDataTemplateData({
        name: dataTemplate.$attributes.name,
        icon: dataTemplate.$attributes.icon,
        description: dataTemplate.$attributes.description,
      })
      console.log(dataTemplate)
      const dataTemplateItems: DataTemplateItem[] = await dataTemplate.dataTemplateItems().get()
      existingDataTemplateItems.current = dataTemplateItems

      const tempDataTemplateItemsData: DataTemplateItemData[] = []
      for (const dataTemplateItem of dataTemplateItems) {
        // fetch data template if needed
        if (
          dataTemplateItem.$attributes.data_force_select &&
          dataTemplateItem.$attributes.data_select_from_data_template
        ) {
          const linkedDataTemplate = await DataTemplate.$query().find(
            dataTemplateItem.$attributes.data_select_from_data_template_id,
          )
          tempDataTemplateItemsData.push({
            id: dataTemplateItem.$attributes.id,
            data_type: dataTemplateItem.$attributes.data_type,
            name: dataTemplateItem.$attributes.name,
            data_force_select: dataTemplateItem.$attributes.data_force_select,
            data_select_from_data_template: linkedDataTemplate,
          })
        } else {
          tempDataTemplateItemsData.push({
            id: dataTemplateItem.$attributes.id,
            data_type: dataTemplateItem.$attributes.data_type,
            name: dataTemplateItem.$attributes.name,
            data_force_select: dataTemplateItem.$attributes.data_force_select,
          })
        }
      }
      setDataTemplateItems(tempDataTemplateItemsData)
      console.log(tempDataTemplateItemsData)
    }

    /**
     * Create new data template item data to the state
     */
    const createNewDataTemplateItemData = (
      data_type: string,
      data_force_select: boolean = false,
      data_select_from_data_template?: DataTemplate,
    ) => {
      setDataTemplateItems((prevDataTemplateItems) => {
        const updatedDataTemplateItems = [...prevDataTemplateItems]
        updatedDataTemplateItems.push({
          id: updatedDataTemplateItems.length,
          data_type: data_type,
          name: "",
          data_force_select: data_force_select,
          data_select_from_data_template: data_select_from_data_template,
        })
        return updatedDataTemplateItems
      })
    }

    /**
     * Set the name of a data template item
     * @param name
     * @param dataTemplateItemId
     */
    const updateDataTemplateItemName = (name: string, dataTemplateItemId: number) => {
      setDataTemplateItems((prevDataTemplateItems) => {
        // Create a copy of the previous state to avoid modifying it directly
        const updatedDataTemplateItems = prevDataTemplateItems.map((element) => {
          if (element.id === dataTemplateItemId) {
            // Update the data_type and data_type_index properties
            return {
              ...element,
              name: name,
            }
          }
          return element
        })
        return updatedDataTemplateItems // Return the updated state
      })
    }

    /**
     * Upload data template items
     * @param dataTemplateItems
     * @param dataTemplate
     * @returns
     */
    const uploadDataTemplateItems = (dataTemplateItems, dataTemplate: DataTemplate) => {
      let dataTemplateItemUploads = []
      dataTemplateItems.forEach((dataTemplateItemData) => {
        if (dataTemplateItemData.data_type != "" && dataTemplateItemData.data_type != null) {
          dataTemplateItemUploads.push(
            dataTemplate.dataTemplateItems().store({
              data_template_id: dataTemplate.$attributes.id,
              data_type: dataTemplateItemData.data_type,
              name: dataTemplateItemData.name,
              data_force_select: dataTemplateItemData.data_force_select,
              data_select_from_data_template_id: dataTemplateItemData.data_force_select
                ? dataTemplateItemData.data_select_from_data_template.$attributes.id
                : undefined,
            }),
          )
        }
      })
      return Promise.all(dataTemplateItemUploads)
    }

    /**
     * Create new data template
     */
    const createNewDataTemplate = () => {
      const dataTemplate = DataTemplate.$query()
        .store({
          name: dataTemplateData.name,
          icon: dataTemplateData.icon,
        })
        .then((dataTemplate) => {
          uploadDataTemplateItems(dataTemplateItems, dataTemplate)
            .then(() => {
              navigation.goBack()
            })
            .catch((error) => {
              setError(ErrorMessage.fromException(error, "DataTemplateItem"))
            })
        })
        .catch((error) => {
          setError(ErrorMessage.fromException(error, "DataTemplate"))
        })
    }

    /**
     * Update existing data template and data template items
     */
    const updateDataTemplate = () => {
      const promises = []
      // handle deleted
      deletedDataTemplateItems.current.forEach((dataTemplateItemId) => {
        promises.push(existingDataTemplate.current.dataTemplateItems().destroy(dataTemplateItemId))
      })

      // handle datatemplate update
      existingDataTemplate.current.$attributes.name = dataTemplateData.name
      existingDataTemplate.current.$attributes.icon = dataTemplateData.icon
      promises.push(existingDataTemplate.current.$save())

      // handle new and existing data template items
      dataTemplateItems.forEach((dataTemplateItemData) => {
        // if the data template item id exists in existng data template items, update it
        if (
          existingDataTemplateItems.current.find(
            (item) => item.$attributes.id == dataTemplateItemData.id,
          )
        ) {
          // existng data template item
          promises.push(
            existingDataTemplate.current.dataTemplateItems().update(dataTemplateItemData.id, {
              name: dataTemplateItemData.name,
            }),
          )
        } else {
          // new data template item
          promises.push(
            existingDataTemplate.current.dataTemplateItems().store({
              data_template_id: existingDataTemplate.current.$attributes.id,
              data_type: dataTemplateItemData.data_type,
              name: dataTemplateItemData.name,
              data_force_select: dataTemplateItemData.data_force_select,
              data_select_from_data_template_id: dataTemplateItemData.data_force_select
                ? dataTemplateItemData.data_select_from_data_template.$attributes.id
                : undefined,
            }),
          )
        }
      })

      return Promise.all(promises)
    }

    /**
     * Handle submit button press
     */
    const handleSubmitPress = () => {
      if (mode.current == "create") {
        createNewDataTemplate()
      } else {
        updateDataTemplate()
          .then(() => {
            navigation.goBack()
          })
          .catch((error) => {
            setError(ErrorMessage.fromException(error, "DataTemplate"))
          })
      }
    }

    return (
      <View style={{ flex: 1 }}>
        <Screen style={$root} preset="scroll">
          <ErrorModal
            error={error}
            onDismiss={() => {}}
            dismissText="Ok"
            tryAgainVisible={true}
            onTryAgain={handleSubmitPress}
            tryAgainText="Try again"
          />
          <IconPicker
            isModalVisible={showIconPicker}
            title={"Choose icon"}
            onCancel={() => {}}
            onSelect={(icon) => {
              // update the icon
              setDataTemplateData((prevDataTemplateData) => {
                const updatedDataTemplateData = {
                  ...prevDataTemplateData,
                  icon: icon,
                  icon_picker_show: false,
                }
                return updatedDataTemplateData
              })
            }}
          />
          <DataTypePicker
            isModalVisible={showTypePicker}
            title={""}
            onCancel={() => {
              setShowTypePicker(false)
            }}
            onSelect={(data_type, data_force_select, data_select_from_data_template) => {
              createNewDataTemplateItemData(
                data_type,
                data_force_select,
                data_select_from_data_template,
              )
              setShowTypePicker(false)
            }}
          />

          <View style={styles.inputsContainer}>
            <View style={{ marginTop: 10, flexDirection: "row" }}>
              <TouchableOpacity
                style={{
                  padding: 5,
                  paddingLeft: 12,
                  paddingTop: 10,
                  paddingRight: 10,
                  borderWidth: 1,
                  borderRadius: 8,
                  borderColor: "#dadae8",
                  margin: 20,
                  marginRight: 0,
                  backgroundColor: "#fff",
                  borderBottomEndRadius: 0,
                  borderTopEndRadius: 0,
                  borderRightWidth: 0,
                }}
                onPress={() => {
                  setShowIconPicker(true)
                }}
              >
                <Text>
                  <FontAwesomeIcon icon={validateFaIcon(dataTemplateData.icon)} size={25} />
                </Text>
              </TouchableOpacity>
              <TextInput
                style={[
                  styles.inputStyle,
                  { marginLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
                ]}
                onChangeText={(data) => setDataTemplateData({ ...dataTemplateData, name: data })}
                placeholder="Template name, e.g. 'Inspection', 'Damage', 'Measurement'"
                placeholderTextColor="#8b9cb5"
                keyboardType="default"
                underlineColorAndroid="#f000"
                returnKeyType="next"
                defaultValue={dataTemplateData.name}
              />
            </View>
            <Divider />

            {dataTemplateItems.map((dataTemplateItem) => (
              <View key={dataTemplateItem.id} style={{ marginTop: 10 }}>
                <TouchableOpacity
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    paddingRight: 15,
                  }}
                  onPress={() => {
                    // remove the item
                    deletedDataTemplateItems.current.push(dataTemplateItem.id)
                    setDataTemplateItems((prevDataTemplateItems) => {
                      const updatedDataTemplateItems = prevDataTemplateItems.filter(
                        (item) => item.id != dataTemplateItem.id,
                      )
                      return updatedDataTemplateItems
                    })
                  }}
                >
                  <Ionicons name="trash-bin" size={25} color="black" />
                </TouchableOpacity>

                {!dataTemplateItem.data_force_select ? (
                  <Text
                    style={{ textAlign: "center" }}
                    tx={("dataTypePicker.dataTypes." + dataTemplateItem.data_type) as TxKeyPath}
                  ></Text>
                ) : (
                  <Text style={{ textAlign: "center" }}>
                    <Text
                      tx={("dataTypePicker.dataTypes." + dataTemplateItem.data_type) as TxKeyPath}
                    ></Text>
                    <Text>
                      {" " + dataTemplateItem.data_select_from_data_template.$attributes.name}
                    </Text>
                  </Text>
                )}
                <View>
                  <TextInput
                    style={styles.inputStyle}
                    onChangeText={(data) => updateDataTemplateItemName(data, dataTemplateItem.id)}
                    placeholder="Name"
                    placeholderTextColor="#8b9cb5"
                    keyboardType="default"
                    underlineColorAndroid="#f000"
                    returnKeyType="next"
                    defaultValue={dataTemplateItem.name}
                  />
                </View>
                <Divider />
              </View>
            ))}
            <View>
              <TouchableOpacity
                style={styles.fieldButtonStyle}
                activeOpacity={0.5}
                onPress={() => {
                  setShowTypePicker(true)
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={["far", "square-plus"]}
                    size={18}
                    style={{ marginRight: 7 }}
                  />
                  <Text style={[styles.buttonTextStyle, { paddingBottom: 8 }]}>Add field</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Screen>
        <View style={{ justifyContent: "flex-end" }}>
          <TouchableOpacity
            style={styles.saveButtonStyle}
            activeOpacity={0.5}
            onPress={handleSubmitPress}
          >
            <Text style={styles.saveButtonTextStyle}>Save</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  },
)

const $root: ViewStyle = {
  flex: 1,
}

const styles = StyleSheet.create({
  inputsContainer: {
    flex: 1,
  },
  dataTemplateButton: {
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  inputStyle: {
    flex: 1,
    color: "black",
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    paddingTop: 10,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: "#dadae8",
    margin: 20,
    backgroundColor: "#fff",
  },

  SectionStyle: {
    flexDirection: "row",
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },

  buttonStyle: {
    backgroundColor: "#fff",
    borderWidth: 2,
    color: "#000",
    borderColor: "#7DE24E",
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },

  fieldButtonStyle: {
    backgroundColor: "#fff",
    borderWidth: 2,
    color: "#000",
    borderColor: "#888",
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },

  saveButtonStyle: {
    backgroundColor: "#7DE24E",
    borderWidth: 0,
    color: "#FFFFFF",
    borderColor: "#7DE24E",
    height: 40,
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 10,
    marginBottom: 10,
    flex: 1,
  },
  buttonTextStyle: {
    color: "#555",
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: "bold",
    fontSize: 16,
  },
  saveButtonTextStyle: {
    color: "#FFFFFF",
    paddingVertical: 10,
    fontWeight: "bold",
    fontSize: 16,
  },
})
