import React, { useEffect } from "react"
import { StyleSheet, View } from "react-native"
import { useState } from "react"
import { colors } from "app/theme"
import { ActivityIndicator } from "react-native"

type LoadingSpinnerProps = {
  show: boolean
}
export const LoadingSpinner = ({ show }: LoadingSpinnerProps) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(show)

  useEffect(() => {
    setShowSpinner(show)
  }, [show])

  return (
    <View>
      {showSpinner && (
        <View style={{ margin: 20 }}>
          <ActivityIndicator size="large" color={colors.brandColor} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({})
