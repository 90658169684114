import React from 'react';
import {useDraggable} from '@dnd-kit/core';

export function TemplateEditorBlock(props) {
    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: props.id,
        type:props.type,
        
      });
      const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      } : undefined;
    
      
      return (
        <button ref={setNodeRef} style={style} {...listeners} {...attributes}>
          {props.children}
        </button>
      );
}