import Config from "../../config"
import { Orion } from "@tailflow/laravel-orion/lib/orion"
import { Model } from "@tailflow/laravel-orion/lib/model"
import AsyncStorage from "@react-native-async-storage/async-storage"
import axios from "axios"

// Set up api

export class OrionClient {
  static setup = (showAuthPageCallback: () => void) => {
    Orion.init(Config.API_URL, Config.API_PATH)
    Orion.makeHttpClientUsing(() => {
      const client = axios.create()
      client.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (error.response && error.response.status == 401) {
            showAuthPageCallback()
          }
          return Promise.reject(error)
        },
      )
      return client
    })

    AsyncStorage.getItem("api_token").then((token) => {
      Orion.setToken(token)
    })
  }
}
