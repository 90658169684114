import React, { useEffect } from "react"
import { StyleSheet, View, Text, Button } from "react-native"
import Modal from "react-native-modal"
import { ErrorMessage } from "app/models/ErrorMessage"
import { useState } from "react"
import { useFocusEffect } from "@react-navigation/native"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { TouchableOpacity } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { ScrollView } from "react-native"
import { SafeAreaView } from "react-native"
import { DataGroup } from "app/models"
import { validateFaIcon } from "app/utils/uiUtils"
import { Col, Row, Grid } from "react-native-easy-grid"
import moment from "moment"
import { Divider } from "@rneui/base"
import { colors } from "app/theme"
import { Data } from "app/models"
import { File } from "app/models"
import { Image } from "react-native"
import { ImageZoom } from "@likashefqet/react-native-image-zoom"
import { Ionicons } from "@expo/vector-icons"

type DataImageProps = {
  data: Data
}
export const DataImage = ({ data }: DataImageProps) => {
  const [image, setImage] = useState<string>()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (data.$attributes.data) {
      new File().getFileBase64(data).then((file) => {
        console.log("Loading image data for")
        console.log(data.$attributes.data)
        setImage(file.$attributes.data)
        console.log("GOT IMG DATA")
      })
    }
  }, [data])

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={showModal}
        animationInTiming={500}
        animationOutTiming={100}
        backdropTransitionInTiming={500}
        backdropTransitionOutTiming={100}
        style={{ margin: 0, backgroundColor: "#000" }}
      >
        <View style={{ flex: 1, flexDirection: "column" }}>
          <View
            style={{
              borderBottomColor: "#aaa",
              borderBottomWidth: 0,
              height: 37,
              alignItems: "flex-end",
            }}
          >
            <TouchableOpacity
              onPress={(event) => {
                event.stopPropagation()
                setShowModal(false)
              }}
              style={{}}
            >
              <Ionicons name="close" size={37} color="white" />
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1 }}>
            <ImageZoom source={{ uri: image }} minScale={0.1} maxScale={30}></ImageZoom>
          </View>
        </View>
      </Modal>

      <TouchableOpacity
        onPress={() => {
          setShowModal(true)
        }}
      >
        <Image source={{ uri: image }} style={{ resizeMode: "cover", height: 50, width: 50 }} />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({})
