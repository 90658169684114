import React, { useEffect } from "react"
import { StyleSheet, View } from "react-native"
import { useState } from "react"
import { colors } from "app/theme"
import { ActivityIndicator } from "react-native"
import { DataTemplate } from "app/models"
import { TouchableOpacity } from "react-native"
import { validateFaIcon } from "app/utils/uiUtils"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { Text } from "react-native"
import { Ionicons } from "@expo/vector-icons"
import Modal from "react-native-modal"
import { ConfirmModal } from "./ConfirmModal"
import moment from "moment"

type DataTemplateButtonProps = {
  dataTemplateProp: DataTemplate
  onSelect: (dataTemplate: DataTemplate) => void
  onDelete?: (dataTemplate: DataTemplate) => void
  onEdit?: (dataTemplate: DataTemplate) => void
}
export const DataTemplateButton = ({
  dataTemplateProp,
  onSelect,
  onDelete,
  onEdit,
}: DataTemplateButtonProps) => {
  const [dataTemplate, setDataTemplate] = useState<DataTemplate>(dataTemplateProp)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showEditConfirm, setShowEditConfirm] = useState(false)

  useEffect(() => {
    setDataTemplate(dataTemplateProp)
  }, [dataTemplateProp])

  return (
    <View style={{}}>
      <TouchableOpacity
        key={dataTemplate.$attributes.id}
        style={{
          justifyContent: "center",
          borderRadius: 10,
          borderWidth: 1,
          borderColor: colors.cardBorder,
          backgroundColor: "#fff",
          marginBottom: 15,
        }}
        activeOpacity={1}
        onPress={() => {
          onSelect(dataTemplate)
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text
            style={{ borderWidth: 0, marginRight: 14, marginLeft: 10, justifyContent: "center" }}
          >
            <FontAwesomeIcon
              icon={validateFaIcon(dataTemplate.$attributes.icon)}
              size={30}
              style={{ paddingTop: 5 }}
            />
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: "left",
            }}
          >
            <View style={{ paddingVertical: 5 }}>
              <Text>{dataTemplate.$attributes.name}</Text>
              <Text>Last used {moment(dataTemplate.$attributes.last_used_at).fromNow()}</Text>
            </View>
          </Text>
          {onDelete && (
            <TouchableOpacity
              onPress={(event) => {
                event.stopPropagation()
                setShowDeleteConfirm(true)
              }}
              style={styles.rightButton}
            >
              <Ionicons name="trash" size={25} color={colors.text} />
            </TouchableOpacity>
          )}
          {onEdit && (
            <TouchableOpacity
              onPress={(event) => {
                event.stopPropagation()
                onEdit(dataTemplate)
              }}
              style={styles.rightButton}
            >
              <Ionicons name="pencil" size={25} color={colors.text} />
            </TouchableOpacity>
          )}
        </View>
      </TouchableOpacity>
      <ConfirmModal
        show={showDeleteConfirm}
        onConfirm={() => {
          setShowDeleteConfirm(false)
          onDelete(dataTemplate)
        }}
        onCancel={() => {
          setShowDeleteConfirm(false)
        }}
        title={"Delete " + dataTemplate.$attributes.name + "?"}
        subTitle="Notes created with this template will not be deleted."
        titleIcon="warning"
        confirmText="Delete"
      ></ConfirmModal>
    </View>
  )
}

const styles = StyleSheet.create({
  rightButton: {
    borderLeftWidth: 1,
    borderLeftColor: colors.cardBorder,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    paddingHorizontal: 8,
  },
})
