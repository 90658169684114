import { Model } from "@tailflow/laravel-orion/lib/model"
import { Data } from "./Data"
import { HasMany } from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany"

export class DataGroup extends Model<
  {
    name?: string
    id?: number
    icon?: string
    data_template_id?: number
    created_at?: Date
    updated_at?: Date
  },
  {},
  {
    datas: Array<Data>
  }
> {
  public $resource(): string {
    return "data_groups"
  }

  public datas(): HasMany<Data> {
    return new HasMany(Data, this)
  }
}
