import React, { FC } from "react"
import { useState, createRef, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from "react-native"

import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { MainTabScreenProps } from "app/navigators"
import { Screen } from "app/components"
import { WebView } from "react-native-webview"
import { useNavigation, CommonActions } from "@react-navigation/native"
import { User, useStores } from "app/models"
import Config from "app/config"
import { ActivityIndicator } from "react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { colors } from "app/theme"
import { Team } from "app/models"
import { ErrorModal } from "app/components/ErrorModal"
import { ErrorMessage } from "app/models/ErrorMessage"
import { logout } from "app/utils/userActions"

// Inside your component:
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface AccountScreenProps extends NativeStackScreenProps<MainTabScreenProps<"login">> {}

export const AccountScreen: FC<AccountScreenProps> = observer(function LoginScreen({ navigation }) {
  //const navigation = useNavigation<MainTabScreenProps<"docs">["navigation"]>()
  const [user, setUser] = useState<User>()
  const [teams, setTeams] = useState<Team[]>()
  const [currentTeam, setCurrentTeam] = useState<Team>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ErrorMessage>()

  useEffect(() => {
    fetchData()
  }, [])

  /**
   * Fetch all the needed data for the page
   */
  async function fetchData() {
    new User()
      .me()
      .then((user) => {
        setUser(user)
        Team.$query()
          .get()
          .then((teams) => {
            setCurrentTeam(
              teams.find((team) => team.$attributes.id == user.$attributes.current_team_id),
            )
            setTeams(teams)
            setLoading(false)
          })
          .catch((error) => {
            console.error(error)
            setError(ErrorMessage.fromException(error, "Team"))
          })
      })
      .catch((error) => {
        console.error(error)
        setError(ErrorMessage.fromException(error, "User"))
      })
  }

  /**
   * Log out user adn remove token from backend
   * @param all
   */
  const handleLogout = (all: boolean) => {
    let action: Promise<User>
    if (all) {
      action = user.logoutAllDevices()
    } else {
      action = user.logout()
    }
    action
      .then(() => {
        logout()
        // navigate to login
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: "login" }],
          }),
        )
      })
      .catch((error) => {
        console.error(error)
        setError(ErrorMessage.fromException(error, undefined, "Logout"))
      })
  }

  /**
   * Switch user's currently active team
   * @param teamId
   * @returns
   */
  const switchTeam = (teamId: number) => () => {
    new User()
      .switchTeam(teamId)
      .then((response) => {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: "main" }],
          }),
        )
      })
      .catch((error) => {
        console.error(error)
        setError(ErrorMessage.fromException(error, undefined, "Switch team"))
      })
  }

  return (
    <View style={styles.mainBody}>
      <ErrorModal error={error} onDismiss={() => {}} dismissText="Ok" tryAgainVisible={false} />

      <ScrollView>
        {loading ? (
          <ActivityIndicator size="large" color={colors.brandColor} />
        ) : (
          <View>
            <Text style={styles.title}>You are logged in as</Text>
            <Text style={styles.email}>{user.$attributes.email}</Text>
            <Text style={styles.title}>Team</Text>
            <Text style={styles.email}>{currentTeam && currentTeam.$attributes.name}</Text>
            <Text style={styles.title}>Switch team</Text>
            {/* List teams here */}
            {teams &&
              teams.map((team) => (
                <TouchableOpacity
                  style={styles.buttonStyle}
                  activeOpacity={0.5}
                  onPress={switchTeam(team.$attributes.id)}
                >
                  <Text key={team.$attributes.id} style={styles.buttonTextStyle}>
                    {team.$attributes.name}
                  </Text>
                </TouchableOpacity>
              ))}
            {/* End of team list */}
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={() => handleLogout(false)}
            >
              <Text style={styles.buttonTextStyle}>Sign out from this device</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.buttonStyle}
              activeOpacity={0.5}
              onPress={() => handleLogout(true)}
            >
              <Text style={styles.buttonTextStyle}>Sign out from all devices</Text>
            </TouchableOpacity>
          </View>
        )}
      </ScrollView>
    </View>
  )
})

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: colors.background,
    alignContent: "center",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10,
    color: colors.text,
  },
  email: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
    color: colors.text,
  },
  title2: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 20,
    color: colors.text,
  },
  code: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 40,
    color: colors.text,
    borderWidth: 1,
    borderColor: colors.text,
    borderRadius: 10,
    marginLeft: 35,
    marginRight: 35,
    padding: 5,
    marginTop: 10,
  },
  SectionStyle: {
    flexDirection: "row",
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: "#7DE24E",
    borderWidth: 0,
    color: "#FFFFFF",
    borderColor: "#7DE24E",
    fontWeight: "bold",
    height: 40,
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 0,
    marginBottom: 10,
  },
  buttonTextStyle: {
    color: "#FFFFFF",
    fontWeight: "bold",
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: "white",
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: "#dadae8",
  },
  registerTextStyle: {
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    alignSelf: "center",
    padding: 10,
  },
  errorTextStyle: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },
})
