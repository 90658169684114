import { Model } from "@tailflow/laravel-orion/lib/model"

export class Document extends Model<{
  name: string
  description: string
}> {
  public $resource(): string {
    return "documents"
  }
}
