import React, { useEffect } from "react"
import { StyleSheet, View, Button } from "react-native"
import { Modal } from "./Modal"
import { useState } from "react"
import { TouchableOpacity } from "react-native-gesture-handler"
import { ScrollView } from "react-native"
import { SafeAreaView } from "react-native"
import { DataTemplateItem } from "app/models"
import { FlatList } from "react-native"
import { ListRenderItem } from "react-native"
import { colors } from "app/theme"
import { Text } from "./Text"
import { TxKeyPath } from "app/i18n"
import { Divider } from "@rneui/base"
import { DataTemplate } from "app/models"
import { SortDirection } from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection"
import { ErrorMessage } from "app/models/ErrorMessage"
import { ActivityIndicator } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { validateFaIcon } from "app/utils/uiUtils"
import { DataGroup } from "app/models"
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator"
import { Col, Row, Grid } from "react-native-easy-grid"

type DataGroupPickerProps = {
  isModalVisible: boolean
  title: string
  dataTemplateId: number
  onSelect: (selectedDataGroup: DataGroup) => void
  onCancel: () => void
}
export const DataGroupPicker = ({
  isModalVisible = true,
  title = "Select",
  dataTemplateId,
  onSelect,
  onCancel,
}: DataGroupPickerProps) => {
  const [modalVisible, setModalVisible] = useState(isModalVisible)
  const [dataGroups, setDataGroups] = useState<DataGroup[]>([])
  const [loading, setLoading] = useState(false)
  let dataTemplateIdR = dataTemplateId

  const hide = () => {
    setModalVisible(false)
  }

  useEffect(() => {
    setModalVisible(isModalVisible)
    dataTemplateIdR = dataTemplateId
    if (isModalVisible) {
      console.log("fetching data groups")
      fetchDataGroups()
    }
    console.log(dataTemplateId)
  }, [isModalVisible, dataTemplateId])

  /**
   * User selected a data type -> return to parent
   * @param data_type
   * @param data_force_select
   * @param data_select_from_data_template
   */
  const dataGroupSelected = (dataGroup: DataGroup) => {
    setDataGroups(undefined)
    hide()
    onSelect(dataGroup)
  }

  /**
   * Fetch all the data groups for the data template
   */
  const fetchDataGroups = () => {
    console.log(dataTemplateIdR)
    setLoading(true)
    DataGroup.$query()
      .filter("data_template_id", FilterOperator.Equal, dataTemplateIdR)
      .sortBy("updated_at", SortDirection.Desc)
      .search()
      .then((response) => {
        setDataGroups(response)
        setLoading(false)
      })
      .catch((error) => {
        alert(ErrorMessage.fromException(error, "DataGroup"))
      })
  }

  /**
   * Render a single data group selection button
   * @param dataGroup
   * @returns
   */
  const renderDataGroupSelection: ListRenderItem<DataGroup> = (dataGroup) => (
    <View style={[styles.typeSelectButton]}>
      <TouchableOpacity
        style={{
          justifyContent: "center",
          backgroundColor: colors.card,
          borderRadius: 10,
          margin: 10,
          padding: 10,
        }}
        activeOpacity={1}
        onPress={() => {
          dataGroupSelected(dataGroup.item)
        }}
      >
        <Text style={{ textAlign: "center" }}>
          <FontAwesomeIcon icon={validateFaIcon(dataGroup.item.$attributes.icon)} size={20} />
        </Text>

        {dataGroup.item.$relations.datas.length > 0 && (
          <View style={{ marginBottom: 10 }}>
            <Divider style={{ margin: 0 }} />
            <View style={{ padding: 10 }}>
              {dataGroup.item.$relations.datas.map((data) => (
                <Row key={data.$attributes.id}>
                  <View key={data.$attributes.id} style={{ flexDirection: "row" }}>
                    <Text>{data.$attributes.name}:</Text>
                    <Text style={{ marginLeft: 10 }}>{data.$attributes.data}</Text>
                  </View>
                </Row>
              ))}
            </View>
          </View>
        )}
      </TouchableOpacity>
    </View>
  )

  return (
    <View>
      <Modal isVisible={modalVisible}>
        <Modal.Container>
          <Modal.Header title={title} />
          <Modal.Body>
            <SafeAreaView style={{ flex: 1, maxHeight: "100%" }}>
              <ScrollView>
                {loading ? (
                  <ActivityIndicator size="large" color={colors.brandColor} />
                ) : (
                  <View>
                    <FlatList
                      data={dataGroups}
                      style={{ flex: 1 }}
                      renderItem={renderDataGroupSelection}
                      keyExtractor={(item) => item.$attributes.id.toString()}
                      numColumns={1}
                    />
                  </View>
                )}
              </ScrollView>
            </SafeAreaView>
          </Modal.Body>
          <Modal.Footer>
            <Button
              title={"cancel"}
              onPress={() => (setDataGroups(undefined), hide(), onCancel())}
            />
          </Modal.Footer>
        </Modal.Container>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    borderRadius: 25,
    borderWidth: 1,
    borderColor: "#000",
    borderStyle: "solid",
  },
  header: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    paddingTop: 10,
    textAlign: "center",
    fontSize: 24,
  },
  typeSelectButton: {
    flex: 1,
    minHeight: 100,
    borderColor: colors.cardBorder,
    borderWidth: 1,
    margin: 10,
    textAlign: "right",
    borderRadius: 10,
    backgroundColor: colors.card,
  },
  body: {
    justifyContent: "center",
    paddingHorizontal: 15,
    minHeight: 100,
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    flexDirection: "row",
  },

  btnContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
})
