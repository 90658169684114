import React, { createContext, useContext, useMemo } from "react";
import {useDroppable, DraggableSyntheticListeners,
  UniqueIdentifier} from '@dnd-kit/core';
import { TemplateEditorContentColumn } from './TemplateEditorContentColumn';
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import {SortableContext, horizontalListSortingStrategy, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';


interface Props {
  id: UniqueIdentifier;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {}
});

export function TemplateEditorContentRow(props) {

  console.log(props)
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    setActivatorNodeRef,
    transform,
    transition,
} = useSortable({
    id: props.id,
    data: {
      child: props.child
    }
  });



  const style = {
    opacity: isDragging ? 0.4 : undefined,
    height:100,
    backgroundColor: "red",
    padding:15,
    margin:10,
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
 

  
  return (
    <div ref={setNodeRef} style={style}>

    <button className="DragHandle"   {...attributes}  {...listeners}>
      <svg viewBox="0 0 20 20" width="12">
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </button> 
    <SortableContext 
          items={props.child}
          strategy={horizontalListSortingStrategy}
        >
          <Allotment vertical={false}>
        {props.child.map((column, id) => (
          // We updated the Droppable component so it would accept an `id`
          // prop and pass it to `useDroppable`
          <TemplateEditorContentColumn key={id} id={props.id+".col."+id} child={column.children}>
          </TemplateEditorContentColumn>
        ))}
      </Allotment>
      </SortableContext>
      
     
    </div>
  );
}


/*<Allotment vertical={false}>
      {props.child.map((column, id) => (
        // We updated the Droppable component so it would accept an `id`
        // prop and pass it to `useDroppable`
        <TemplateEditorContentColumn key={id} id={props.id+".col."+id} child={column.children}>
        </TemplateEditorContentColumn>
      ))}
    </Allotment>*/