import { Model } from "@tailflow/laravel-orion/lib/model"
import { DataTemplateItem } from "./DataTemplateItem"
import { HasMany } from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany"

export class DataTemplate extends Model<
  {
    name: string
    id?: number
    icon: string
    last_used_at?: Date
    description?: string
  },
  {},
  {
    dataTemplateItems: Array<DataTemplateItem>
  }
> {
  public $resource(): string {
    return "data_templates"
  }

  public dataTemplateItems(): HasMany<DataTemplateItem> {
    return new HasMany(DataTemplateItem, this)
  }
}
