import React, { useEffect } from "react"
import { StyleSheet, View, Text, Button } from "react-native"
import RNModal from "react-native-modal"
import { Modal } from "./Modal"
import { ErrorMessage } from "app/models/ErrorMessage"
import { useState } from "react"
import { useFocusEffect } from "@react-navigation/native"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { TouchableOpacity } from "react-native-gesture-handler"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { ScrollView } from "react-native"
import { SafeAreaView } from "react-native"
import { DataGroup } from "app/models"
import { validateFaIcon } from "app/utils/uiUtils"
import { Col, Row, Grid } from "react-native-easy-grid"
import moment from "moment"
import { Divider } from "@rneui/base"
import { colors } from "app/theme"

type DataGroupOverviewProps = {
  dataGroup: DataGroup
  children?: React.ReactNode
}
export const DataGroupPreview = ({ dataGroup, children }: DataGroupOverviewProps) => {
  return (
    <View style={{}}>
      {dataGroup.$relations.datas.length > 0 && (
        <View>
          {dataGroup.$relations.datas.length == 0 ? (
            <Text style={{ textAlign: "left" }}>{dataGroup.$attributes.name}</Text>
          ) : (
            <View style={{}}>
              {dataGroup.$relations.datas.map((data) => (
                <View key={data.$attributes.id}>
                  {data.$attributes.data_type == "text" && (
                    <Text style={{ textAlign: "left" }}>{data.$attributes.data}</Text>
                  )}
                  {data.$attributes.data_type == "number" && (
                    <Text style={{ textAlign: "left" }}>{data.$attributes.data}</Text>
                  )}
                </View>
              ))}
            </View>
          )}
        </View>
      )}
      {children}
    </View>
  )
}

const styles = StyleSheet.create({})
