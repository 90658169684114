import { Model } from "@tailflow/laravel-orion/lib/model"
import { BelongsTo } from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo"
import { DataGroup } from "./DataGroup"
import Config from "app/config"

export class Data extends Model<{
  name?: string
  id?: number
  icon?: string
  data_type?: string
  description?: string
  data_template_item_id: number
  data_group_id: number
  data_selected: boolean
  data_selected_data_group_id?: number
  data?: string
  file_data?: string
  data_file_name?: string
  data_file_access_token?: string
}> {
  public $resource(): string {
    return "datas"
  }

  public data_selected_data_group(): BelongsTo<DataGroup> {
    return new BelongsTo(DataGroup, this)
  }

  public getFileDownloadUrl(): string {
    return (
      Config.API_ROOT +
      "/files/data/" +
      this.$attributes.id +
      "/file?access_token=" +
      this.$attributes.data_file_access_token
    )
  }
}
