import React, { useEffect } from "react"
import { StyleSheet, View, Text, Button } from "react-native"
import Modal from "react-native-modal"
import { ErrorMessage } from "app/models/ErrorMessage"
import { useState } from "react"
import { useFocusEffect } from "@react-navigation/native"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { TouchableOpacity } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { ScrollView } from "react-native"
import { SafeAreaView } from "react-native"
import { DataGroup } from "app/models"
import { validateFaIcon } from "app/utils/uiUtils"
import { Col, Row, Grid } from "react-native-easy-grid"
import moment from "moment"
import { Divider } from "@rneui/base"
import { colors } from "app/theme"
import { Data } from "app/models"
import { File } from "app/models"
import { Image } from "react-native"
import { ImageZoom } from "@likashefqet/react-native-image-zoom"
import { Ionicons } from "@expo/vector-icons"
import { Linking } from "react-native"

type DataFilePreviewProps = {
  data: Data
}
export const DataFilePreview = ({ data }: DataFilePreviewProps) => {
  /**
   * Download file
   */
  // const downloadFile = () => {
  //   new File().getFileBase64(data).then((file) => {
  //     console.log("Loading image data for")
  //     console.log(data.$attributes.data)
  //     setImage(file.$attributes.data)
  //     console.log("GOT IMG DATA")
  //   })
  // }

  return (
    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
      <View style={{}}>
        <Text style={{ textAlign: "left" }}>{data.$attributes.data_file_name}</Text>
      </View>

      <TouchableOpacity
        style={{
          borderWidth: 1,
          borderColor: "#aaa",
          borderRadius: 5,
          paddingHorizontal: 6,
          paddingVertical: 4,
          marginLeft: 10,
        }}
        onPress={(event) => {
          // prevent default behaviour
          event.stopPropagation()
          Linking.openURL(data.getFileDownloadUrl())
        }}
      >
        <Text>
          Open file
          <Ionicons name="document-outline" size={18} color="black" style={{ marginLeft: 3 }} />
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({})
