import React, { FC } from "react"
import { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
  StyleSheet,
  TextInput,
  View,
  Text,
  ScrollView,
  Image,
  Keyboard,
  TouchableOpacity,
  KeyboardAvoidingView,
} from "react-native"
import { RootStackScreenProps } from "app/navigators"
import { CommonActions } from "@react-navigation/native"
import { User } from "app/models"
import { ActivityIndicator, Platform } from "react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import DeviceInfo from "react-native-device-info"
import { colors } from "app/theme"
import { Orion } from "@tailflow/laravel-orion/lib/orion"
import { ErrorModal } from "app/components/ErrorModal"
import { ErrorMessage } from "app/models/ErrorMessage"
import { login } from "app/utils/userActions"

export const LoginScreen: FC<RootStackScreenProps<"login">> = observer(function LoginScreen({
  navigation,
}) {
  const [userEmail, setUserEmail] = useState("")
  const [userPassword, setUserPassword] = useState("")
  const [loading, setLoading] = useState(true)
  const [errortext, setErrortext] = useState("")
  const [error, setError] = useState<ErrorMessage>()

  useEffect(() => {
    checkLogin()
  }, [])

  /**
   * Check login - if user is logged in then redirect to main navigator
   * @returns
   */
  const checkLogin = async () => {
    AsyncStorage.getItem("api_token").then((token) => {
      if (token != "" && token != null) {
        Orion.setToken(token)
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: "main" }],
          }),
        )
      }
      setLoading(false)
    })
  }

  /**
   * Handle login button press
   * @returns
   */
  const handleSubmitPress = () => {
    if (!userEmail) {
      alert("Please fill Email")
      return
    }
    if (!userPassword) {
      alert("Please fill Password")
      return
    }
    setLoading(true)
    let deviceName = "TODO"
    //DeviceInfo.getModel() + " " + DeviceInfo.getBrand() + " " + DeviceInfo.getDeviceNameSync()

    // set deviceName manually for web platform
    if (Platform.OS == "web") {
      deviceName = "Web App - " + navigator.userAgent
    }

    new User()
      .login(userEmail, userPassword, deviceName)
      .then((user) => {
        login(user.$attributes.token)
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: "main" }],
          }),
        )
      })
      .catch((error) => {
        console.error(error)
        setError(ErrorMessage.fromException(error, undefined, "Login"))
        setLoading(false)
      })
  }

  return (
    <View style={styles.mainBody}>
      <ErrorModal error={error} onDismiss={() => {}} dismissText="Ok" tryAgainVisible={false} />

      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flex: 1,
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {loading ? (
          <ActivityIndicator size="large" color={colors.brandColor} />
        ) : (
          <View>
            <KeyboardAvoidingView enabled>
              <View style={{ alignItems: "center" }}>
                <Image
                  source={require("assets/logo/templagon.svg")}
                  style={{
                    width: "50%",
                    height: 100,
                    resizeMode: "contain",
                    margin: 30,
                  }}
                />
              </View>
              <View style={styles.SectionStyle}>
                <TextInput
                  style={styles.inputStyle}
                  onChangeText={(UserEmail) => setUserEmail(UserEmail)}
                  placeholder="Enter Email" //dummy@abc.com
                  placeholderTextColor="#8b9cb5"
                  autoCapitalize="none"
                  keyboardType="email-address"
                  returnKeyType="next"
                  /*onSubmitEditing={() => passwordInputRef.current && passwordInputRef.current.focus()}*/
                  underlineColorAndroid="#f000"
                  blurOnSubmit={false}
                />
              </View>
              <View style={styles.SectionStyle}>
                <TextInput
                  style={styles.inputStyle}
                  onChangeText={(UserPassword) => setUserPassword(UserPassword)}
                  placeholder="Enter Password" //12345
                  placeholderTextColor="#8b9cb5"
                  keyboardType="default"
                  /*ref={passwordInputRef}*/
                  onSubmitEditing={Keyboard.dismiss}
                  blurOnSubmit={false}
                  secureTextEntry={true}
                  underlineColorAndroid="#f000"
                  returnKeyType="next"
                />
              </View>
              {errortext != "" ? <Text style={styles.errorTextStyle}>{errortext}</Text> : null}
              <TouchableOpacity
                style={styles.buttonStyle}
                activeOpacity={0.5}
                onPress={handleSubmitPress}
              >
                <Text style={styles.buttonTextStyle}>LOGIN</Text>
              </TouchableOpacity>
              <Text
                style={styles.registerTextStyle}
                onPress={() => navigation.navigate("register")}
              >
                Create a new account
              </Text>
            </KeyboardAvoidingView>
          </View>
        )}
      </ScrollView>
    </View>
  )
})

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: colors.background,
    alignContent: "center",
  },
  SectionStyle: {
    flexDirection: "row",
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: "#7DE24E",
    borderWidth: 0,
    color: "#FFFFFF",
    borderColor: "#7DE24E",
    height: 40,
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color: "#FFFFFF",
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: "black",
    backgroundColor: "#fff",
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: "#dadae8",
  },
  registerTextStyle: {
    color: "#000",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 16,
    alignSelf: "center",
    padding: 10,
    textDecorationLine: "underline",
  },
  errorTextStyle: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },
})
