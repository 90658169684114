import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { Screen, Text } from "app/components"
import { TouchableOpacity } from "react-native-gesture-handler"
import { HomeStackScreenProps } from "app/navigators"
import { Document } from "app/models/Document"
import { useEffect, useState } from "react"
import { ActivityIndicator } from "react-native"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface DashboardScreenProps extends HomeStackScreenProps<"dashboard"> {}

export const DashboardScreen: FC<DashboardScreenProps> = observer(function DashboardScreen({
  navigation,
}) {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const navigation = useNavigation()const [documents, setDocuments] = useState<Document[]>([]);
  const [documents, setDocuments] = useState<Document[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchDocuments() {
      try {
        const fetchedDocuments = await Document.$query().get()
        setDocuments(fetchedDocuments)
        setLoading(false)
      } catch (error) {
        console.error("Error fetching documents:", error)
      }
    }

    fetchDocuments()

    navigation.setOptions({
      title: "Dashboard",
    })
  }, [])

  return (
    <Screen style={$root} preset="scroll">
      <Text text="Dashboard is not ready yet..." />
    </Screen>
  )
})

const $root: ViewStyle = {
  flex: 1,
}
