import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View } from "react-native"
import { DataDashboardStackScreenProps } from "app/navigators"
import { LoadingSpinner, Screen, Text } from "app/components"
import { useState } from "react"
import { DataTemplate } from "app/models/DataTemplate"
import { StyleSheet } from "react-native"
import { colors } from "app/theme"
import { ErrorModal } from "app/components"
import { ErrorMessage } from "app/models/ErrorMessage"
import { SortDirection } from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection"
import { DataTemplateButton } from "app/components"
import { useFocusEffect } from "@react-navigation/native"
import { Button } from "app/components"
import { Icon } from "app/components"
import { Ionicons } from "@expo/vector-icons"

export const DataSettingsScreen: FC<DataDashboardStackScreenProps<"datasettings">> = observer(
  function DataSettingsScreen({ navigation, route }) {
    const [dataTemplates, setDataTemplates] = useState<DataTemplate[]>([])
    const [dataTemplatesLoading, setDataTemplatesLoading] = useState(true)
    const [error, setError] = useState<ErrorMessage>()

    useFocusEffect(
      React.useCallback(() => {
        fetchData()
      }, []),
    )

    // useEffect(() => {
    //   fetchData()
    // }, [])

    /**
     * Fetch data for the view
     */
    async function fetchData() {
      setDataTemplatesLoading(true)

      DataTemplate.$query()
        .sortBy("last_used_at", SortDirection.Desc)
        .search()
        .then((response) => {
          setDataTemplates(response)
          setDataTemplatesLoading(false)
        })
        .catch((error) => {
          setError(ErrorMessage.fromException(error, "Data Templates"))
          setDataTemplatesLoading(false)
        })
    }

    /**
     * Delete selected data template
     */
    async function deleteDataTemplate(dataTemplate: DataTemplate) {
      dataTemplate
        .$destroy()
        .then(() => {
          fetchData()
        })
        .catch((error) => {
          setError(ErrorMessage.fromException(error, "Data Template"))
        })
    }

    /**
     * Edit selected data template
     */
    async function editDataTemplate(dataTemplate: DataTemplate) {
      navigation.navigate("edit_data_template", { dataTemplate: dataTemplate })
    }

    /**
     * Create new data template
     */
    async function newDataTemplate() {
      navigation.navigate("create_data_template")
    }

    return (
      <Screen style={styles.mainBody} preset="scroll">
        <ErrorModal
          error={error}
          onDismiss={() => {}}
          dismissText="Ok"
          tryAgainVisible={true}
          onTryAgain={fetchData}
          tryAgainText="Try again"
        />

        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: 15,
            marginTop: 10,
          }}
        >
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>Manage note types</Text>
          </View>

          <View style={{}}>
            <Button
              text=""
              preset="default"
              onPress={() => {
                newDataTemplate()
              }}
              style={{
                paddingVertical: 0,
                paddingHorizontal: 0,
                backgroundColor: "transparent",
                borderWidth: 0,
                alignItems: "center",
              }}
              renderChildrenInsideView={true}
              textStyle={[{ fontSize: 16 }, { color: "#000" }]}
              pressedTextStyle={[{ fontSize: 16 }, { color: "#000" }]}
            >
              <Ionicons name="add-circle" size={35} color="black" />
            </Button>
          </View>
        </View>
        <LoadingSpinner show={dataTemplatesLoading} />

        {!dataTemplatesLoading && (
          <View style={{ marginHorizontal: 15, marginVertical: 10 }}>
            {dataTemplates &&
              dataTemplates.map((dataTemplate, index) => (
                <DataTemplateButton
                  key={index}
                  dataTemplateProp={dataTemplate}
                  onSelect={(dataTemplate) => {}}
                  onDelete={(dataTemplate) => {
                    deleteDataTemplate(dataTemplate)
                  }}
                  onEdit={(dataTemplate) => {
                    editDataTemplate(dataTemplate)
                  }}
                ></DataTemplateButton>
              ))}
          </View>
        )}
      </Screen>
    )
  },
)

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    backgroundColor: colors.background,
    alignContent: "center",
  },
  btnContainer: {},
  dataTemplateButton: {
    flex: 1,
    borderColor: colors.cardBorder,
    borderWidth: 1,
    margin: 10,
    marginBottom: 0,
    textAlign: "right",
    borderRadius: 10,
    backgroundColor: colors.card,
  },
  loading: {
    marginTop: 20,
  },
  btnListContainer: {},
  btnico: {
    fontSize: 40,
  },
  title: {
    fontSize: 20,
    textAlign: "left",
    color: colors.text,
  },
  email: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
    color: colors.text,
  },
  title2: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 20,
    color: colors.text,
  },
  code: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 40,
    color: colors.text,
    borderWidth: 1,
    borderColor: colors.text,
    borderRadius: 10,
    marginLeft: 35,
    marginRight: 35,
    padding: 5,
    marginTop: 10,
  },
  SectionStyle: {
    flexDirection: "row",
    height: 40,
    marginTop: 20,
    marginLeft: 35,
    marginRight: 35,
    margin: 10,
  },
  buttonStyle: {
    backgroundColor: "#7DE24E",
    borderWidth: 0,
    color: "#FFFFFF",
    borderColor: "#7DE24E",
    fontWeight: "bold",
    height: 40,
    alignItems: "center",
    borderRadius: 30,
    marginLeft: 35,
    marginRight: 35,
    marginTop: 0,
    marginBottom: 10,
  },
  buttonTextStyle: {
    color: "#FFFFFF",
    fontWeight: "bold",
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    color: "white",
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: "#dadae8",
  },
  registerTextStyle: {
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    alignSelf: "center",
    padding: 10,
  },
  errorTextStyle: {
    color: "red",
    textAlign: "center",
    fontSize: 14,
  },
})
