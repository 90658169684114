import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DataAddStackScreenProps } from "app/navigators"
import { Screen, Text } from "app/components"
import { ActivityIndicator, Platform } from "react-native"
import { useState } from "react"
import { DataTemplate } from "app/models/DataTemplate"
import { TouchableOpacity } from "react-native"
import { StyleSheet } from "react-native"
import { colors } from "app/theme"
import { Card } from "@rneui/themed"
import { Ionicons } from "@expo/vector-icons"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { useFocusEffect } from "@react-navigation/native"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { View, FlatList, Dimensions } from "react-native"
import { ListRenderItem } from "react-native"
import { SortDirection } from "@tailflow/laravel-orion/lib/drivers/default/enums/sortDirection"

export const DataTemplatesScreen: FC<DataAddStackScreenProps<"datatemplates">> = observer(
  function DataTemplatesScreen({ navigation, route }) {
    const [dataTemplates, setDataTemplates] = useState<DataTemplate[]>([])
    const [loading, setLoading] = useState(true)
    const notification = route.params.notification
    const availableIcons = [...Object.values(fas), ...Object.values(far), ...Object.values(fab)]
    const defaultIcon = "list-ol"
    const { width } = Dimensions.get("window")
    const numColumns = Math.ceil(width / 200)

    const validateIcon = (iconName: string): IconName => {
      if (availableIcons.some((iconData) => iconData.iconName === iconName)) {
        return iconName as IconName
      } else {
        return defaultIcon as IconName
      }
    }

    useEffect(() => {
      //fetchData()
    }, [notification])

    useFocusEffect(
      React.useCallback(() => {
        fetchData()
      }, []),
    )

    useFocusEffect(
      React.useCallback(() => {
        // Clear the notification param from the route when the screen comes into focus

        // Clean up function (optional)
        return () => {
          // Any cleanup code here (if needed)
          if (notification !== "") {
            navigation.setParams({ notification: "" })
          }
        }
      }, [notification]),
    )

    /**
     * Fetch all the needed data for the page
     */
    async function fetchData() {
      const datat = await DataTemplate.$query()
        .sortBy("last_used_at", SortDirection.Desc)
        .search(100)
      setDataTemplates(datat)
      setLoading(false)
    }

    const renderItem: ListRenderItem<DataTemplate> = (dataTemplate) => (
      <View style={[styles.dataTemplateButton]}>
        <TouchableOpacity
          key={dataTemplate.item.$attributes.id}
          activeOpacity={0.5}
          onPress={() => {
            dataTemplate.item
              .dataTemplateItems()
              .get()
              .then((dataTemplateItems) => {
                navigation.navigate("datagroup", {
                  dataTemplate: dataTemplate.item,
                  dataTemplateItems: dataTemplateItems,
                })
              })
          }}
        >
          <Ionicons
            name="add-circle-outline"
            size={30}
            color="#555"
            style={{ right: 0, margin: -5, height: 10 }}
          />
          <View style={styles.btnContainer}>
            <FontAwesomeIcon
              icon={validateIcon(dataTemplate.item.$attributes.icon as IconName)}
              size={styles.btnico.fontSize}
            />
            <Text style={{ textAlign: "center" }}>{dataTemplate.item.$attributes.name}</Text>
          </View>
        </TouchableOpacity>
      </View>
    )

    return (
      <Screen style={styles.mainBody} preset="scroll">
        {notification != "" && (
          <View>
            <Card>
              <Card.Title>{notification}</Card.Title>
            </Card>
          </View>
        )}

        {loading ? (
          <ActivityIndicator style={styles.loading} size="large" color={colors.brandColor} />
        ) : (
          <FlatList
            data={dataTemplates}
            style={{ flex: 1 }}
            renderItem={renderItem}
            keyExtractor={(item) => item.$attributes.id.toString()}
            numColumns={numColumns}
          />
        )}
      </Screen>
    )
  },
)

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    backgroundColor: colors.background,
    alignContent: "center",
  },
  btnContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  dataTemplateButton: {
    flex: 1,
    minHeight: 100,
    borderColor: colors.cardBorder,
    borderWidth: 1,
    margin: 10,
    textAlign: "right",
    borderRadius: 10,
    backgroundColor: colors.card,
  },
  loading: {
    marginTop: 20,
  },
  btnListContainer: {
    marginTop: 20,
    flexWrap: "wrap",
    justifyContent: "center",
    flex: 1,
  },
  btnico: {
    fontSize: 40,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10,
    color: colors.text,
  },
  email: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
    color: colors.text,
  },
  title2: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 20,
    color: colors.text,
  },
  code: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 40,
    color: colors.text,
    borderWidth: 1,
    borderColor: colors.text,
    borderRadius: 10,
    marginLeft: 35,
    marginRight: 35,
    padding: 5,
    marginTop: 10,
  },
})
